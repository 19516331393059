/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */

.columnForm {
  &.inputGroup {
    display: flex;
    gap: 4px;

    &.vertical {
      flex-direction: column;
      align-items: stretch;
    }
  }
}