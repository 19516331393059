/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
.toasterRoot {
  --animation-duration: 0.2s;
  position: absolute;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: auto;
  overflow-y: scroll;
  overflow-x: hidden;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
  }

  &.collapsed {
    overflow: hidden;
  }

  & > .toastWrapper {
    position: absolute;
    width: 100%;
    cursor: pointer;
    pointer-events: auto;
    clip-path: inset(0 0 0 0);
    opacity: 0;
    bottom: 0;
    transform-origin: bottom center;
    transform: translateY(0px);

    &.hideBottom {
      clip-path: inset(0 0 70% 0);
    }
  }

  & > .applyTransitions {
    transition-property: transform, opacity;
    transition-duration: var(--animation-duration);
    transition-timing-function: ease linear;
  }
}

.toasterControls {
  --animation-duration: 0.2s;
  transition-duration: var(--animation-duration);
  transition-timing-function: ease linear;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  height: 20px;
  font-size: 14px;
  z-index: 150;

  & > button {
    flex: 0 0 auto;
    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 0 6px;
    gap: 12px;
    font-weight: bold;
    color: var(--color-neutral-900);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
}
