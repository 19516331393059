/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */


.textInput {
  --color: var(--color-high-emphasis-text);
  --placeholder-color: var(--color-neutral-650);
  --bg-color: var(--color-surface-dark3);
  --root-shadow:
    inset 0px -1px 0px 0px var(--color-neutral-300),
    inset 0px 1px 0px 0px var(--color-neutral-0);
  --font-size: 14px;
  --line-height: 32px;
  --container-padding-vert: 0px;
  --container-padding-horiz: 10px;
  --multiline-padding: 6px 10px;
  --container-gap: 8px;

  &:hover {
    --placeholder-color: var(--color-neutral-800);
    --bg-color: var(--color-surface-dark2);
  }

  &:focus-within {
    --placeholder-color: var(--color-neutral-800);
    --outline-color: var(--color-primary-interactive);
    --shadow-color: var(--color-purple-400);
  }

  &.faultError {
    --outline-color: var(--color-red-500);
    --shadow-color: var(--color-red-200);
  }

  &.faultWarning {
    --outline-color: var(--color-yellow-500);
    --shadow-color: var(--color-yellow-50);
  }

  &.small {
    --font-size: 13px;
    --line-height: 24px;
    --container-padding-vert: 1px;
    --container-padding-horiz: 7px;
  }

  &.disabled {
    --color: var(--color-disabled-type);
    --placeholder-color: var(--color-neutral-550);
    --bg-color: var(--color-surface-dark2);
  }


  &.multiline {
    --container-padding-vert: 0px;
    --container-padding-horiz: 0px;
  }

  &.fullHeight {
    height: 100%;
  }

  & {
    border-radius: 4px;
    background-color: var(--bg-color);
    box-shadow: var(--root-shadow);
    transition: background-color 250ms, box-shadow 250ms;
    display: inline-flex;

    &.asBlock {
      display: flex;
    }

    & > .container {
      flex: 1 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      gap: var(--container-gap);
      border-radius: 4px;
      border: 1px solid var(--outline-color, transparent);
      box-shadow: 0 0 0 2px var(--shadow-color, transparent);
      transition: border-color 250ms, box-shadow 250ms;
      padding: var(--container-padding-vert) var(--container-padding-horiz);
      font-size: var(--font-size);
      line-height: var(--line-height);

      & > .adornment {
        display: flex;
        color: var(--color);
      }

      & > .adornmentButton {
        align-self: center;
        margin-left: calc(-1 * var(--container-gap));
        // position the button 2px from the end.
        margin-right: calc(-1 * var(--container-padding-horiz) + 2px);

        &.multiline {
          align-self: flex-end;
          margin-left: 'unset';
          margin-right: 6px;
          margin-bottom: 4px;
        }
      }

      & > input,
      & > textarea {
        width: 100%;
        font-family: Inter, Helvetica, Arial, sans-serif;
        background-color: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        color: var(--color);
        transition: color 250ms;

        &:focus {
          outline: 0;
        }

        &::placeholder {
          color: var(--placeholder-color);
        }
      }

      & > input {
        height: var(--line-height);
        text-align: var(--input-justify, start);

        // That is a bit hacky but it successfully removes the ugly background
        // autofill that chrome and safari apply for email/password fields
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          transition: background-color 0s 600000s, color 0s 600000s;
        }
      }

      & > textarea {
        padding: var(--multiline-padding);

        &::webkit-resizer {
          border: 2px solid red;
        }
      }
    }
  }
}
