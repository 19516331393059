/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
html,
body {
  height: 100%;
  overflow: hidden;
}

*,
input,
button,
textarea {
  font-family: Inter, Helvetica, Arial, sans-serif;
}

hr {
  background-color: var(--color-neutral-350);
  width: 100%;
  height: 1px;
  margin: 0;
  border: none;
}

.fontWeight700 {
  font-weight: 700; // aka "bold"
}

.formLayout {
  & .formElement {
    &.label {
      /** The .formLayout.label selector is designed to format form input
      labels and may be further constrained by the additional classes 'primary'
      (the default) or 'secondary', each engendering different styling. */
      --label-font-size: 14px;
      --label-line-height: 20px;
      --label-font-weight: 600;
      --label-text-color: var(--color-high-emphasis-text);

      &.secondary {
        --label-font-size: 13px;
        --label-line-height: 16px;
        --label-font-weight: 400;
        --label-text-color: var(--color-high-emphasis-text);
      }

      &.disabled {
        --label-text-color: var(--color-disabled-type);
      }

      font-size: var(--label-font-size);
      line-height: 1.0;
      font-weight: var(--label-font-weight);
      color: var(--label-text-color);
      display: inline-flex;
    }

    &.control {
      /** The .formLayout.control selector is designed to wrap two elements: a
      label and a control (specifically, a RadioButton or a CheckBox. */
      --control-flex-direction: row;

      display: inline-flex;
      align-items: center;
      gap: 8px;

      &.vertical {
        --control-flex-direction: column;
      }

      flex-direction: var(--control-flex-direction);
    }

    &.controlSeries {
      /** The .formLayout.controlSeries selector is designed to wrap a series
      of .formLayout.control elements (i.e. labelled radio buttons or labelled
      checkboxes). */
      display: flex;
      flex-wrap: wrap;
      gap: 8px 24px;

      &.vertical {
        flex-direction: column;
      }
    }
  }
}

.flexColumnLayout {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  gap: 0;
  overflow: hidden;

  &.fullHeight {
    height: 100%;
  }

  & > .flexItem {
    flex: 0 0 auto;

    &.elastic {
      flex: 1 1 auto;
    }

    &.scrollable {
      overflow: auto;
    }
  }
}

.flexBetweenLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .endAligned {
    align-items: flex-end;
  }
}

.tabularNumber {
  font-variant-numeric: tabular-nums;
  white-space: nowrap;

  &.compact {
    letter-spacing: -0.1ch;
  }
}

.tabularCell.wrap {
  white-space: normal;
}

#root {
  height: 100%;
  position: relative;
  z-index: 1;
}

#modals {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  background-color: rgb(0, 0, 0, 0.5);
  backdrop-filter: blur(3px);
  opacity: 1;
  transition: opacity 250ms, z-index 0ms 0ms;
  overflow: hidden;

  &:empty {
    z-index: -1;
    opacity: 0;
    transition: opacity 250ms, z-index 0ms 250ms;
  }
}

#menus {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 3;

  &:empty {
    z-index: -1;
  }
}

body:has(.horizontal-dragger-active) {
  &, * {
    cursor: col-resize !important;
  }
}

body:has(.vertical-dragger-active) {
  &, * {
    cursor: row-resize !important;
  }
}

.shaking {
  animation: earthquake 400ms;
}

@keyframes earthquake {
  0% {
    transform: rotate(1.78deg) translate3d(3.40px, -8.28px, 0) scale(1.05);
    filter: blur(2.00px);
  }
  4% {
    transform: rotate(3.20deg) translate3d(5.11px, -7.14px, 0) scale(1.05);
    filter: blur(1.84px);
  }
  8% {
    transform: rotate(-3.73deg) translate3d(-6.11px, -5.35px, 0) scale(1.04);
    filter: blur(1.69px);
  }
  12% {
    transform: rotate(3.08deg) translate3d(-4.08px, -1.04px, 0) scale(1.04);
    filter: blur(1.55px);
  }
  16% {
    transform: rotate(-0.43deg) translate3d(-0.82px, 2.02px, 0) scale(1.04);
    filter: blur(1.41px);
  }
  20% {
    transform: rotate(2.69deg) translate3d(-5.97px, 5.29px, 0) scale(1.03);
    filter: blur(1.28px);
  }
  24% {
    transform: rotate(-1.41deg) translate3d(-4.72px, -1.42px, 0) scale(1.03);
    filter: blur(1.16px);
  }
  28% {
    transform: rotate(-2.07deg) translate3d(-3.35px, -3.52px, 0) scale(1.03);
    filter: blur(1.04px);
  }
  32% {
    transform: rotate(2.16deg) translate3d(1.27px, -1.61px, 0) scale(1.02);
    filter: blur(0.92px);
  }
  36% {
    transform: rotate(0.29deg) translate3d(-3.54px, -1.24px, 0) scale(1.02);
    filter: blur(0.82px);
  }
  40% {
    transform: rotate(0.32deg) translate3d(-0.29px, -0.71px, 0) scale(1.02);
    filter: blur(0.72px);
  }
  44% {
    transform: rotate(0.49deg) translate3d(-0.10px, 1.84px, 0) scale(1.02);
    filter: blur(0.63px);
  }
  48% {
    transform: rotate(0.65deg) translate3d(-1.56px, 0.13px, 0) scale(1.01);
    filter: blur(0.54px);
  }
  52% {
    transform: rotate(-1.08deg) translate3d(0.48px, 1.42px, 0) scale(1.01);
    filter: blur(0.46px);
  }
  56% {
    transform: rotate(-0.63deg) translate3d(-0.74px, -0.39px, 0) scale(1.01);
    filter: blur(0.39px);
  }
  60% {
    transform: rotate(-0.42deg) translate3d(-1.46px, 0.86px, 0) scale(1.01);
    filter: blur(0.32px);
  }
  64% {
    transform: rotate(0.23deg) translate3d(-1.23px, -0.56px, 0) scale(1.01);
    filter: blur(0.26px);
  }
  68% {
    transform: rotate(0.26deg) translate3d(0.65px, -0.17px, 0) scale(1.01);
    filter: blur(0.20px);
  }
  72% {
    transform: rotate(0.29deg) translate3d(-0.12px, 0.55px, 0) scale(1.00);
    filter: blur(0.16px);
  }
  76% {
    transform: rotate(0.08deg) translate3d(-0.15px, -0.37px, 0) scale(1.00);
    filter: blur(0.12px);
  }
  80% {
    transform: rotate(0.14deg) translate3d(-0.15px, 0.02px, 0) scale(1.00);
    filter: blur(0.08px);
  }
  84% {
    transform: rotate(-0.02deg) translate3d(0.18px, 0.13px, 0) scale(1.00);
    filter: blur(0.05px);
  }
  88% {
    transform: rotate(0.02deg) translate3d(0.02px, 0.10px, 0) scale(1.00);
    filter: blur(0.03px);
  }
  92% {
    transform: rotate(0.01deg) translate3d(0.00px, 0.03px, 0) scale(1.00);
    filter: blur(0.01px);
  }
  96% {
    transform: rotate(0.00deg) translate3d(0.00px, 0.00px, 0) scale(1.00);
    filter: blur(0.00px);
  }
  100% {
    transform: rotate(0.00deg) translate3d(-0.00px, 0.00px, 0) scale(1.00);
    filter: blur(0.00px);
  }
  100% {
    transform: matrix(1,0,0,1,0,0);
    filter: blur(0);
  }
}