/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.cardContainer {
  z-index: 5;
  min-width: 100px;
  max-width: 296px;
  width: 100%;
  max-height: 600px;
  height: fit-content;
  overflow-y: auto;
  overflow-x: hidden;

  display: flex;
  flex-direction: column;

  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--color-neutral-150);
  pointer-events: auto;
  justify-self: end;

  .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
  }

  & > .header {
    font-weight: 600;
    padding-bottom: 8px;
    font-size: 13px;
  }

  & > .data {
    font-size: 12px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    position: relative;
    padding: 8px 0;

    & > .entry {
      --button-size: 28px;
      --gap: 4px;
      --margin-right: -calc(var(--button-size) + var(--gap));
      --button-visibility: hidden;

      cursor: default;

      &:hover {
        --margin-right: 0;
        --button-visibility: visible;
      }

      & > .key {
        flex-shrink: 0;
      }

      & > .value {
        flex-shrink: 1;
        white-space: nowrap;
        overflow: hidden;
        display: inline-flex;
        align-items: center;
        min-height: var(--button-size);
        gap: var(--gap);

        > .content {
          flex: 1 1 auto;
          margin-right: var(--margin-right);
          overflow: hidden;
          position: relative;
        }

        > .copyButton {
          background-color: var(--color-neutral-150);
          visibility: var(--button-visibility);
    
          &:hover {
            background-color: var(--color-neutral-400);
          }
        }
      }
    }


  }
}
