/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */

.inputDescription {
  --text-color: var(--color-low-emphasis-text);

  &.error {
    --text-color: var(--color-red-600);
  }

  &.warning {
    --text-color: var(--color-yellow-600);
  }

  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--text-color);

  display: flex;
  align-items: center;
  gap: 6px;
}
