/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */

.expressionInput {
  --font-size: 12px;

  & {
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: var(--color-surface-dark3);
    padding: 6px 8px;
    width: 100%;
    font-size: var(--font-size);
    font-family: monospace;

    &:focus {
      outline: 0;
      border-color: var(--color-primary-interactive);
    }

    & * {
      font-family: inherit;
    }

    & .placeholder {
      color: var(--placeholder-text-color);
      text-wrap: wrap;
      // The Slate library does its own sizing calculations for placeholders
      // to make it fit within the Editor element. This extra padding needs to
      // be added in order for the sizing calculations to account for the
      // additional padding added to the Editor element
      // https://github.com/ianstormtaylor/slate/blob/b04b7e0e98aebf5558ed44bdad3149cdba61812b/packages/slate-react/src/components/editable.tsx#L930-L933
      padding: 6px 16px 6px 0;
    }

    &.fault-error {
      border-color: var(--color-red-500);
    }

    &.fault-warning {
      border-color: var(--color-yellow-500);
    }
  }
}