/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */

.messagePanel {
  --primary-color: var(--color-purple-600);
  --secondary-color: var(--color-purple-100);
  --tertiary-color: var(--color-neutral-900);
  --link-color: white;
  --transition-duration: 500ms;
  --header-gap: 8px;
  --icon-panel-width: 16px;

  &.success {
    --primary-color: var(--color-green-500);
    --secondary-color: var(--color-green-50);
    --tertiary-color: var(--color-green-900);
  }

  &.warning {
    --primary-color: var(--color-yellow-500);
    --secondary-color: var(--color-yellow-50);
    --tertiary-color: var(--color-yellow-900);
  }

  &.error {
    --primary-color: var(--color-red-500);
    --secondary-color: var(--color-red-50);
    --tertiary-color: var(--color-red-900);
  }

  &.dev-error, &.toast.dev-error {
    --bg-color: var(--color-purple-600);
    --primary-color: var(--color-red-500);
    --secondary-color: var(--color-red-50);
    --tertiary-color: var(--color-red-900);
  }

  &.neutral {
    --primary-color: var(--color-neutral-500);
    --secondary-color: var(--color-neutral-200);
    --tertiary-color: var(--color-yellow-900);
  }

  &.sectionMessage {
    --border-color: var(--primary-color);
    --bg-color: var(--secondary-color);
    --text-color: var(--tertiary-color);
    --control-color: var(--tertiary-color)
  }

  &.toast {
    --border-color: var(--color-neutral-500);
    --bg-color: var(--color-surface-medium2);
    --text-color: var(--color-high-emphasis-text);
    --control-color: var(--color-high-emphasis-text);
    --link-color: var(--color-blue-800);
  }

  & {
    border: 1px solid var(--border-color);
    background-color: var(--bg-color);
    padding: 12px;
    border-radius: 8px;
    transition: border-color var(--transition-duration), background-color var(--transition-duration);
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .message {
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    word-break: break-word;
  }

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    gap: 8px;

    & > .icon {
      flex: 0 0 auto;
      display: flex;
      padding: 2px;
      width: var(--icon-panel-width);
      min-width: var(--icon-panel-width);
      transition: color var(--transition-duration);
    }

    & > .details {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      gap: 4px;
      color: var(--text-color);
      transition: color var(--transition-duration);

      & > .title {
        font-size: 13px;
        line-height: 16px;
        font-weight: 600;
      }
    }

    & > .control {
      flex: 0 0 auto;
      display: inline-flex;

      & > button {
        flex: 0 0 auto;
        width: 16px;
        height: 16px;
        background-color: transparent;
        border: 0;
        margin: 0;
        padding: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        color: var(--control-color);
        transition: background-color 250ms, color var(--transition-duration);
        cursor: pointer;

        &:hover {
          background-color: rgb(255, 255, 255, 0.25);
        }
      }
    }
  }

  & > .content {
    color: var(--text-color);
    transition: color var(--transition-duration);
    max-height: 60vh;
    overflow: auto;
    padding-left: calc(var(--icon-panel-width) + var(--header-gap));

    &.shifted {
      margin-top: -24px;
    }
  }

  & > .links {
    display: flex;
    align-items: baseline;
    flex-wrap: wrap;
    gap: 5px;
    padding-left: calc(var(--icon-panel-width) + var(--header-gap));

    &:not(:empty) {
      padding-top: 4px;
    }

    & > .link {
      font-size: 13px;
      font-weight: 600;
      color: var(--link-color);
      text-decoration: none;
      appearance: none;
      border: 0;
      padding: 0;
      margin: 0;
      outline: 0;
      background-color: transparent;
      white-space: nowrap;
      cursor: pointer;
    }

    & > .linkSeparator {
      color: var(--color-neutral-700);
      font-size: 12px;
    }
  }
}
