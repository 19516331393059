/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */

.tableDialogSubtitle {
  display: flex;
  gap: 8px;
  align-items: baseline;
}

.tableDialogContent {
  height: 100%;
  display: flex;
  flex-direction: column;

  & .fileSelection {
    height: 100%;
    overflow: hidden;
  }

  & .collapsibleScroll {
    overflow: auto;
  }

  & .formSection {
    background-color: var(--color-surface-dark2);
    border-radius: 6px;
    transition: height 500ms, opacity 500ms;
    padding: 16px;

    &.primary {
      --border-color: transparent;
      --icon-color: var(--color-neutral-550);

      &.dragging {
        --border-color: var(--color-purple-300);
        --icon-color: var(--color-high-emphasis-text);
      }

      display: flex;
      justify-content: center;
      align-items: center;
      padding: 32px 16px;
      border: 2px dashed var(--border-color);
      transition: border 500ms;

      & .uploadScreen {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;

        & > .icon {
          display: flex;
          color: var(--icon-color);
          transition: color 500ms;
        }

        & > .guide {
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: var(--color-high-emphasis-text);
          padding: 0 0 8px;
        }
      }
    }

    &.secondary {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: stretch;
      gap: 8px;

      & > .file {
        --icon-color: var(--color-neutral-650);

        &:hover {
          --icon-color: var(--color-high-emphasis-text);
        }

        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;

        & > .filename {
          font-size: 13px;
          line-height: 16px;
          flex: 1 1 auto;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        & > .control {
          color: var(--icon-color);
          transition: color 500ms;
          cursor: pointer;
        }
      }

      & > .preview {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;

        & > .records {
          display: flex;
          justify-content: space-around;
          align-items: flex-start;
          flex-wrap: wrap;
          gap: 16px;

          & > .record {
            display: flex;
            flex-direction: column;
            align-items: stretch;
            background-color: var(--color-surface-dark3);
            padding: 5px;
            gap: 8px;

            & > .label {
              flex: 0 0 auto;
              font-size: 12px;
              font-weight: 500;
              color: var(--color-low-emphasis-text);
              max-width: 130px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            & > .chart {
              flex: 0 0 auto;
              display: flex;
            }
          }
        }

        & > .axisSelect {
          display: flex;
          justify-content: flex-end;
          align-items: center;
          padding: 5px;
          gap: 8px;

          & > .label {
            font-size: 13px;
          }
        }
      }
    }
  }
}
