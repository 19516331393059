/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */

.banner {
    --warning-color: var(--color-yellow-50);
    --error-color: var(--color-red-50);
    --info-color: var(--color-blue-50);
    --font-size: 13px;
    --banner-height: 40px;

    position: relative;
    padding-left: 24px;
    padding-right: 24px;
    width: 100%;
    top: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    overflow-y: hidden;
    height: var(--banner-height);

    &.closing {
        animation: popUp 0.2s ease 0s 1 normal forwards;
    }

    &.info {
        background-color: var(--info-color);
        --icon-color: var(--color-blue-400);
        --text-color: var(--highEmphasisText);
    }
    &.error {
        background-color: var(--error-color);
        --icon-color: var(--color-red-500);
        --text-color: var(--color-red-900);
    }
    &.warning {
        background-color: var(--warning-color);
        --icon-color: var(--color-yellow-500);
        --text-color: var(--color-yellow-900);
    }
     &.fatal {
        background-color: var(--error-color);
        --icon-color: var(--color-red-500);
        --text-color: var(--color-red-900);
    }

    & > .dismissButton {
        flex: 0 0 auto;
    }

    & > .bannerMessage {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        overflow: hidden;
        text-overflow: ellipsis;

        & > .icon {
            flex: 0 0 auto;
            color: var(--icon-color);
        }
        & >.messageText {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--text-color);
            font-size: var(--font-size);
        }
        & > .linkText {
            padding: 4px;
            flex: 0 0 auto;
            display: flex;
            align-items: center;
            & > .link {
                font-size: var(--font-size);
                font-weight: normal;
                color: var(--text-color);
                text-decoration: underline;
                appearance: none;
                border: 0;
                padding: 0;
                margin: 0;
                outline: 0;
                background-color: transparent;
                white-space: nowrap;
                cursor: pointer;
            }
        }
    }

    & > .control {
        flex: 0 0 auto;
        display: inline-flex;
        padding-left: 8px;

        & > button {
          flex: 0 0 auto;
          width: 16px;
          height: 16px;
          background-color: transparent;
          border: 0;
          margin: 0;
          padding: 0;
          display: inline-flex;
          justify-content: center;
          align-items: center;
          color: var(--control-color);
          transition: background-color 250ms, color var(--transition-duration);
          cursor: pointer;

          &:hover {
            background-color: rgb(255, 255, 255, 0.25);
          }
        }
      }
}

@keyframes popUp {
    from {
        height: var(--banner-height);
    }
    to {
        height: 0;
    }
}