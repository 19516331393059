/* Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved. */

.listManager {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 8x;
  transition: gap var(--transition-duration, 500ms);

  &.empty {
    gap: 0px;
  }

  & > .itemsContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0px;

    & > .itemContainer {
      &.animating {
        transition: transform var(--transition-duration, 500ms);
      }
    }

    & .itemWrapper {
      // Flexbox gap is ordinarily a preferred method of spacing the items, but
      // (a) we need some padding when we show the background color on hover,
      // and (b) we animate the collapse of deleted items, but when it's removed
      // from the DOM, a 'gap' value would cause a post-animation jump.
      padding: 8px 0px;
    }
  }

  & > .controls {
    display: flex;
    justify-content: flex-end;
    gap: 16px;
  }

  .defaultContent {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 8px;
    padding: 0 0 8px;
    font-size: 13px;
    font-weight: 400;
    color: var(--color-high-emphasis-text);

    & > .heading {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;

      & > .title {
        font-size: 13px;
        font-weight: 400;
        color: var(--color-low-emphasis-text);
      }

      & > .controls {
        display: flex;
        align-items: bottom;
        gap: 6px;
        flex: 0 0 auto;
      }
    }
  }

  & button.control {
    padding: 0;
    margin: 0;
    border: 0;
    width: 12px;
    height: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    color: var(--color-low-emphasis-text);
    transition: color 250ms, transform 100ms;
    cursor: pointer;

    &:hover {
      color: var(--color-high-emphasis-text);
    }

    &:not(:disabled):active {
      transform: translateY(1px);
    }

    &:disabled {
      color: var(--color-disabled-type);
      cursor: auto;
    }
  }
}
