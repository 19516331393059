/* Copyright 2023 Luminary Cloud, Inc. All Rights Reserved. */
.modalContainer {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  // If we have a multi-modal flow, this will hide all modals except the last
  &:not(:last-of-type) {
    visibility: hidden;
  }
}
