/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.labelContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 8px;

  max-width: fit-content;
  min-width: 180px;
  width: 100%;
  height: fit-content;

  background-color: var(--color-neutral-150);
  padding: 8px;
  border-radius: 4px;
  pointer-events: auto;
  z-index: 2;
  justify-self: center;

  & > .iconWrapper {
    flex-shrink: 0;
  }

  & > .header {
    font-weight: 600;
    font-size: 12px;
    flex-shrink: 0;
  }

  & > .subtitle {
    flex-shrink: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 13px;
    color: var(--color-neutral-800);
    padding-right: 4px;
  }
}
