/* Copyright 2023 Luminary Cloud, Inc. All Rights Reserved. */

.paginationControl {
  display: flex;
  align-items: center;
  gap: 12px;

  & > .pageSizes {
    display: flex;
  }

  & > .pageControls {
    display: flex;
    align-items: center;
    gap: 6px;

    & > .pageControl {
      display: flex;

      & > button {
        background-color: var(--color-surface-light2);
        color: var(--color-medium-emphasis-text);
        border: 0;
        outline: 0;
        box-sizing: border-box;
        min-width: 16px;
        padding: 0 4px;
        height: 16px;
        border-radius: 2px;
        font-size: 10px;
        line-height: 16px;
        font-weight: 600;
        transition: opacity 250ms;
        display: flex;
        justify-content: center;
        align-items: center;

        &.current {
          background-color: var(--color-primary-cta);
        }

        &.sliceControlButton {
          background-color: transparent;
        }

        &:enabled {
          cursor: pointer;
        }

        &:disabled {
          opacity: 0.5;
        }
      }
    }
  }
}
