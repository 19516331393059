/* Copyright 2022 Luminary Cloud, Inc. All Rights Reserved. */
.sparkLine {
  display: inline-flex;
  width: var(--width, 100);
  height: var(--height, 100);
  overflow: visible;
  position: relative;
}

.sparkLineTooltip {
  font-weight: 500;
  color: var(--color-high-emphasis-text);
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  padding: 4px;

  & > .charts {
    display: grid;
    grid-template-columns: auto auto;
    align-items: center;
    justify-items: end;
    line-height: 1;
    gap: 4px 8px;

    & > .recordLabel {
      font-weight: 400;
      white-space: nowrap;
    }

    & > .recordValue {
      font-weight: 600;
      white-space: nowrap;
    }
  }
}
