/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */

.dataSelectUI {
  --display-cursor: pointer;
  --label-user-select: text;
  --field-bg-color: var(--color-neutral-450);
  --field-shadow: 0px 1px 0px 0px rgba(24, 25, 30, 0.16),
    0px 1px 0px 0px rgba(244, 244, 247, 0.16) inset;
  --label-color: var(--color-high-emphasis-text);
  --base-padding: 8px 12px;
  --font-size: 14px;
  --line-height: 20px;
  --outline: 0 black solid;

  &.small {
    --base-padding: 6px 8px;
    --font-size: 13px;
    --line-height: 16px;
  }

  &.readOnly {
    --display-cursor: text;
  }

  &.disabled {
    --display-cursor: auto;
    --label-user-select: text;
    --field-bg-color: var(--color-neutral-350);
    --field-shadow: 0px 1px 0px 0px rgba(24, 25, 30, 0.16),
      0px 1px 0px 0px rgba(244, 244, 247, 0.06) inset;
    --label-color: var(--color-neutral-700);
  }

  &.minimal {
    --field-bg-color: transparent;
    --field-shadow: none;

    &.disabled {
      --field-bg-color: transparent;
      --label-color: var(--color-neutral-500);
    }
  }

  &.enabled {
    &:hover {
      --field-bg-color: var(--color-neutral-550);
      --field-shadow: 0px 1px 0px 0px rgba(24, 25, 30, 0.16),
        0px 1px 0px 0px rgba(244, 244, 247, 0.12) inset;
    }

    &:focus-within {
      --field-bg-color: var(--color-neutral-450);
      --outline: 1px solid var(--color-primary-interactive);
    }

    &:active {
      --field-bg-color: var(--color-neutral-550);
      --outline: 0 solid black;
    }
  }

  &.filled {
    &.fault-warning {
      --field-shadow: inset 0 0 0 1px var(--color-yellow-500);

      &:focus-within {
        --field-shadow: inset 0 0 0 1px var(--color-yellow-500),
          0 0 0 2px var(--color-yellow-50);
      }
    }

    &.fault-error {
      --field-shadow: inset 0 0 0 1px var(--color-red-500);

      &:focus-within {
        --field-shadow: inset 0 0 0 1px var(--color-red-500),
          0 0 0 2px var(--color-red-50);
      }
    }
  }

  display: inline-flex;
  flex: 1;
  flex-direction: column;
  outline: 0;
  // The max-width allows the text-overflow: ellipsis to work on children. Without it, we need
  // overflow: hidden, but it cuts box-shadow unnecessarily so we should avoid it if possible.
  max-width: 100%;
  position: relative;

  &.block-flow {
    display: flex;
  }

  & .label {
    font-weight: 400;
    font-size: var(--font-size);
    line-height: var(--line-height);
    color: var(--label-color);
    user-select: var(--label-user-select);
    --webkit-user-select: var(--label-user-select);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  & > .sizing {
    position: absolute;
    top: 0;
    left: 0;
    height: 0;
    overflow: hidden;

    & .sizingRow {
      padding: 0 8px;
      display: flex;
      gap: 8px;

      & > .icon {
        flex: 0 0 auto;
        width: 13px;
        height: 13px;
      }

      & > .label {
        flex: 0 0 auto;
        font-weight: 600;
      }
    }
  }

  & > .field {
    border-radius: 4px;
    background-color: var(--field-bg-color);
    padding: var(--base-padding);
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    outline: var(--outline);
    cursor: var(--display-cursor);
    box-shadow: var(--field-shadow);
    transition: background-color 250ms, box-shadow 1000ms, outline 1000ms;
    overflow: hidden;

    & > .icon {
      display: flex;
      color: var(--label-color);
    }
  }
}
