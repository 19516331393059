/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */

.buttonTabs {
  display: inline-grid;
  grid-auto-flow: column;
  gap: 1px;
  background-color: var(--color-surface-dark3);
  padding: 1px;
  border-radius: 5px; // 4px from individual tabs + 1px padding

  &.asBlock {
    display: grid;
  }

  &.equalWidths {
    grid-auto-columns: 1fr;
  }

  & > .tabWrapper {
    display: flex;

    & > * {
      flex: 1 1 auto;
    }
  }
}
