/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */
.toggleSwitch {
  --knob-diameter: calc(2 * var(--knob-radius));
  --knob-color: var(--knob-enabled-false-color);
  --gutter-color: var(--gutter-enabled-false-color);
  --cursor: pointer;
  --outline-color: none;
  --gutter-shadow: inset 0px -1px 0px var(--color-neutral-300);

  &.on {
    --knob-color: var(--knob-enabled-true-color);
    --gutter-color: var(--gutter-enabled-true-color);
    --gutter-shadow: none;
  }

  &:hover {
    --knob-color: var(--knob-hover-false-color);
    --gutter-color: var(--gutter-hover-false-color);

    &.on {
      --knob-color: var(--knob-hover-true-color);
      --gutter-color: var(--gutter-hover-true-color);
    }
  }

  &:focus-visible {
    --outline-color: var(--color-purple-800)
  }

  &:active {
    --knob-color: var(--knob-active-false-color);
    --gutter-color: var(--gutter-active-false-color);

    &.on {
      --knob-color: var(--knob-active-true-color);
      --gutter-color: var(--gutter-active-true-color);
    }
  }


  &.disabled {
    --knob-color: var(--knob-disabled-false-color);
    --gutter-color: var(--gutter-disabled-false-color);
    --cursor: auto;
    --gutter-shadow: none;

    &.on {
      --knob-color: var(--knob-disabled-true-color);
      --gutter-color: var(--gutter-disabled-true-color);
    }
  }

  display: inline-flex;
  width: var(--width);
  border-radius: calc(var(--knob-radius) + var(--knob-inset));
  transition: box-shadow 250ms;
  margin: 1px; // to make room for outline when focused

  &:focus-visible {
    outline: solid 1px var(--outline-color);
  }

  & > .gutter {
    flex: 1 1 auto;
    padding: var(--knob-inset);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: var(--gutter-color);
    border-radius: calc(var(--knob-radius) + var(--knob-inset));
    cursor: var(--cursor);
    box-shadow: var(--gutter-shadow);
    transition: background-color 250ms, box-shadow 250ms;

    & > .knob {
      flex: 0 0 auto;
      height: var(--knob-diameter);
      width: var(--knob-diameter);
      background-color: var(--knob-color);
      border-radius: 50%;
      transition: transform 250ms, background-color 250ms;
    }
  }
}
