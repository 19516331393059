/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
.luminaryTab {
  --transition-duration: 250ms;
  --nominal-bg-color: var(--color-neutral-150);
  --text-color: var(--color-low-emphasis-text);
  --font-size: 13px;
  --tab-bg-color: transparent;
  --shadow-color: transparent;
  --border-color: transparent;
  --border-radius-internal: 8px; // defines top radius
  --border-radius-external: 8px; // defines external bottom radius and spacing around tab
  --border-width: 1px;
  --lip-opacity: 0;
  --padding-top: 6px;
  --padding-left: 8px;
  --padding-right: 8px;
  --button-padding: 4px;
  --tab-height: 36px;
  --main-justify: center;
  --main-cursor: pointer;
  --divider-width: 1px;
  --divider-height: 12px;
  --divider-bg: var(--color-neutral-650);

  &.small {
    --border-radius-internal: 4px;
    --padding-top: 6px;
    --tab-height: 24px;
  }

  &:hover {
    --tab-bg-color: var(--nominal-bg-color);
    --shadow-color: var(--color-surface-light1);
    --border-color: var(--color-neutral-0);
    --lip-opacity: 1;
  }

  &.foreground {
    --tab-bg-color: var(--nominal-bg-color);
    --shadow-color: var(--color-surface-light1);
    --border-color: var(--color-neutral-0);
    --text-color: var(--color-high-emphasis-text);
    --lip-opacity: 1;
  }

  &.closable {
    --main-justify: space-between;
  }

  &.disabled {
    --text-color: var(--color-disabled-type);
    --main-cursor: auto;
  }

  display: inline-flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0;
  cursor: var(--main-cursor);

  &.elastic {
    flex: 1 1 auto;
  }

  & > .body {
    flex: 0 0 auto;
    display: flex;
    align-items: stretch;
    overflow: hidden;
    color: var(--text-color);
    text-decoration: none;
    position: relative;

    & > .spacer {
      width: calc(var(--border-radius-external) - var(--border-width));
      flex: 0 0 auto;
    }

    & > .divider {
      width: var(--divider-width);
      height: var(--divider-height);
      position: absolute;
      left: 0;
      top: calc(50% + (var(--tab-height) - 100%) / 2 - var(--border-width));
      background-color: var(--divider-bg);
      transform: translate(-50%, -50%);
      opacity: var(--divider-opacity);
    }

    & > .main {
      flex: 1 1 auto;
      display: flex;
      justify-content: var(--main-justify);
      align-items: center;
      overflow: hidden;
      gap: 3px;
      border-style: solid;
      border-width: var(--border-width) var(--border-width) 0;
      border-color: var(--border-color);
      border-radius: var(--border-radius-internal) var(--border-radius-internal) 0 0;
      background-color: var(--tab-bg-color);
      font-size: var(--font-size);
      line-height: 1.0;
      font-weight: 600;
      height: calc(var(--tab-height) - var(--border-radius-external));
      padding-top: calc(var(--padding-top) - var(--border-width));
      padding-right: var(--padding-right);
      padding-left: var(--padding-left);
      box-shadow: inset 0px 1px 0px var(--shadow-color);
      transition-duration: var(--transition-duration);
      transition-property: background-color, box-shadow, border-color, color;

      & > .label {
        flex: 1 1 auto;
        overflow: hidden;
        white-space: nowrap;
        text-align: center;
        position: relative;
      }

      & > .control {
        flex: 0 0 auto;

        & > button {
          padding: var(--button-padding);
          display: flex;
          color: inherit;
          background-color: transparent;
          border: 0;
          outline: 0;
          cursor: not-allowed;
          opacity: 0.375;
          border-radius: 3px;
          transition-duration: var(--transition-duration);
          transition-property: background-color, opacity;

          &:enabled {
            cursor: pointer;
            opacity: 1;

            &:hover {
              background-color: var(--color-neutral-250);
            }
          }
        }
      }
    }
  }

  & > .foot {
    flex: 0 0 auto;
    align-self: stretch;
    display: flex;
    height: var(--border-radius-external);
    position: relative;

    & > .lip {
      flex: 0 0 auto;
      display: flex;
      width: var(--border-radius-external);
      height: var(--border-radius-external);
      opacity: var(--lip-opacity);
      transition: opacity var(--transition-duration);
      bottom: 0;
      position: absolute;

      &.left {
        left: 0;

        &:after {
          border-right: var(--border-width) solid var(--color-neutral-0);
          border-bottom-right-radius: var(--border-radius-external);
        }
      }

      &.right {
        right: 0;

        &:after {
          border-left: var(--border-width) solid var(--color-neutral-0);
          border-bottom-left-radius: var(--border-radius-external);
        }
      }

      &.left:after,
      &.right:after {
        content: "";
        width: var(--border-radius-external);
        height: var(--border-radius-external);
        background: var(--nominal-bg-color);
        border-bottom: var(--border-width) solid var(--color-neutral-0);
      }
    }

    & > .base {
      flex: 1 1 auto;
      background-color: var(--tab-bg-color);
      transition: background-color var(--transition-duration);
    }
  }
}

.luminaryTabWrapper {
  --divider-opacity: 1;

  display: inline-flex;
  flex-shrink: 1;
  overflow: hidden;

  &:first-child {
    --divider-opacity: 0;
  }

  &:hover, &.active {
    --divider-opacity: 0;

    + .luminaryTabWrapper {
      --divider-opacity: 0;
    }
  }
}