/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
.luminaryTabs {
  position: relative;
  overflow: auto;

  &::-webkit-scrollbar {
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &:before {
    content: '';
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    height: 1px;
    width: 100%;
    background-color: var(--color-neutral-0);
  }

  & > .content {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 0;
  }
}
