/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */

.nodeSubselect {
  --box-bg-color: var(--color-surface-dark3);
  --border-color: var(--box-bg-color);
  --padding: 4px 8px;

  & {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  & > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;

    & > .title {
      color: var(--color-input-placeholder-text);
      font-weight: 500;
      font-size: 13px;
    }
  }

  & > .controls {
    display: flex;
    gap: 4px;
    align-items: center;
  }

  & > .box {
    &.disabled {
      --box-bg-color: var(--color-surface-dark1);
    }

    &:focus:not(.disabled),
    &.active {
      --border-color: var(--color-primary-cta);
    }

    padding: var(--padding);
    background-color: var(--box-bg-color);
    border-radius: 4px;
    border: 1px solid var(--border-color);
    transition: background-color 250ms;
    outline: none;

    &:not(.disabled) {
      cursor: text;
    }

    & > .nodeTags {
      min-height: 56px;
      max-height: 240px;
      overflow: auto;

      & > .message {
        font-size: 13px;
        line-height: 28px;
        height: 28px;
        color: var(--color-input-placeholder-text);
        font-weight: 400;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        padding: 0;
      }
    }
  }
}
