/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */


.progressBar {
  --bar-animation-name: none;
  --border-radius: 0;

  &.rounded {
    --border-radius: calc(var(--height) / 2);
  }

  height: var(--height);
  background-color: var(--bg-color);
  border-radius: var(--border-radius);
  position: relative;

  &.indeterminate {
    --bar-animation-name: progress-indeterminate;
  }

  & > .bar {
    position: absolute;
    top: 0;
    height: 100%;
    width: var(--bar-width);
    background-color: var(--bar-color);
    border-radius: var(--border-radius);
    transition: width var(--bar-transition);
    animation-name: var(--bar-animation-name);
    animation-duration: 2000ms;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }

  @keyframes progress-indeterminate {
    0% {
      left: 0;
      right: calc(100% - var(--height));
    }

    10% {
      left: 0;
    }

    35% {
      right: 0;
    }

    50% {
      left: calc(100% - var(--height));
      right: 0;
    }

    60% {
      right: 0;
    }

    85% {
      left: 0;
    }

    100% {
      left: 0;
      right: calc(100% - var(--height));
    }
  }
}
