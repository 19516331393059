/* Copyright 2022 Luminary Cloud, Inc. All Rights Reserved. */
.formControlLabel {
  --label-color: var(--color-high-emphasis-text);

  &.disabled {
    --label-color: var(--color-disabled-type);
  }

  display: flex;
  gap: 8px;
  align-items: baseline;
  font-size: 13px;

  & > .control {
    display: flex;
  }

  & > .label {
    & .labelText {
      color: var(--label-color);
    }
  }
}
