/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */
.sliderCommon {
  --slider-cursor: grab;
  --transition-delay: 250ms;
  --gutter-color: var(--color-neutral-450);
  --indicator-color: var(--color-primary-cta);
  --thumb-color: var(--color-neutral-900);
  --thumb-border-color: var(--color-purple-800);
  --bar-shadow: inset 0 1px 1px rgb(var(--color-neutral-900-rgb), 0.16);

  &.dragging {
    --slider-cursor: grabbing;
    --transition-delay: 0ms;
  }

  &.disabled {
    --slider-cursor: auto;
    --gutter-color: var(--color-neutral-350);
    --indicator-color: var(--color-neutral-550);
    --thumb-color: var(--color-neutral-700);
    --thumb-border-color: var(--thumb-color);
    --bar-shadow: none;
  }

  position: relative;
  min-height: calc(2 * var(--thumb-radius));
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;

  &:focus {
    outline: 0;
  }

  & > .gutter {
    height: var(--gutter-height, 4px);
    background-color: var(--gutter-color);
    border-radius: calc(var(--gutter-height) / 2);
    position: relative;
    box-shadow: var(--bar-shadow);
    transition-duration: var(--transition-delay);
    transition-property: left, width, transform, background-color;
    pointer-events: none;

    & > .indicator {
      position: absolute;
      top: 0px;
      height: 100%;
      background-color: var(--indicator-color);
      border-radius: inherit;
      pointer-events: none;
      box-shadow: inherit;
      transition-duration: var(--transition-delay);
      transition-property: left, width, transform, background-color;
    }

    & > .stops {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      & > .stopMark {
        position: absolute;
        top: 20%;
        height: 60%;
        width: 1px;
        background-color: rgba(255, 255, 255, 0.25);
        transform: translateX(-50%);
      }
    }
  }

  & > .readout {
    --readout-bg-color: rgb(0, 0, 0, 0.8);
    --pointer-half-width: 6px;

    & {
      position: absolute;
      z-index: var(--z-index);
      bottom: 100%;
      transform: translateX(-50%);
      display: flex;
      flex-direction: column;
      align-items: center;
      opacity: 0;
      z-index: -1;
      transition: opacity 500ms, z-index 0ms 500ms, left var(--transition-delay);

      &.active {
        opacity: 1;
        z-index: 1;
        transition: opacity 500ms, z-index 0ms 0ms, left var(--transition-delay);
      }

      &.disabled {
        display: none;
      }

      & > .body {
        --min-translate: calc(50% - 2 * var(--pointer-half-width));
        --delta-translate: calc(-100% + 4 * var(--pointer-half-width));
        background-color: var(--readout-bg-color);
        color: var(--color-high-emphasis-text);
        font-size: 13px;
        border-radius: 4px;
        padding: 0.5em;
        line-height: 1.0;
        font-weight: 500;
        white-space: nowrap;
        transform: translateX(calc(var(--min-translate) + var(--value-param) * var(--delta-translate)));
        transition: transform var(--transition-delay);
      }

      & > .pointer {
        border-width: 8px var(--pointer-half-width) 0px;
        border-style: solid;
        border-color: var(--readout-bg-color) transparent transparent;
      }
    }
  }

  & > .thumb {
    --thumb-border-size: 0;
    --thumb-scale: 1;

    &:focus-within,
    &:hover,
    &:active {
      --thumb-border-size: 1px;
    }

    &:active {
      --thumb-scale: 1.3333;
    }

    position: absolute;
    top: 50%;
    left: 0;
    transform: translateX(-50%) translateY(-50%);
    cursor: var(--slider-cursor);
    z-index: 1;
    transition: left var(--transition-delay);

    & > .control {
      box-sizing: border-box;
      width: calc(2 * var(--thumb-radius));
      height: calc(2 * var(--thumb-radius));
      border: var(--thumb-border-size) solid var(--thumb-border-color);
      background-color: var(--thumb-color);
      border-radius: 50%;
      transform: translateY(var(--thumb-offset)) scale(var(--thumb-scale));
      transform-origin: 50% 50%;
      transition-duration: var(--transition-delay);
      transition-property: box-shadow, background-color, border-color, border-width, transform;

      &:focus {
        outline: 0;
      }
    }
  }
}
