/* Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved. */
:root {
  --color-neutral-900: #f4f4f7;
  --color-neutral-850: #e8e8eb;
  --color-neutral-800: #c7c7ca;
  --color-neutral-750: #bebec1;
  --color-neutral-700: #acacb0;
  --color-neutral-650: #88898d;
  --color-neutral-600: #7e7f83;
  --color-neutral-550: #75767a;
  --color-neutral-500: #6b6c71;
  --color-neutral-450: #626368;
  --color-neutral-400: #595a5e;
  --color-neutral-350: #505155;
  --color-neutral-300: #46474c;
  --color-neutral-250: #343539;
  --color-neutral-200: #2f3034;
  --color-neutral-150: #2a2b30;
  --color-neutral-100: #212227;
  --color-neutral-50: #18191e;
  --color-neutral-0: #0f1215;
  --color-transparent: rgb(0, 0, 0, 0);

  --color-neutral-900-rgb: 244, 244, 247;
  --color-neutral-850-rgb: 232, 232, 235;
  --color-neutral-800-rgb: 199, 199, 202;
  --color-neutral-750-rgb: 190, 190, 193;
  --color-neutral-700-rgb: 172, 172, 176;
  --color-neutral-650-rgb: 136, 137, 141;
  --color-neutral-600-rgb: 126, 127, 131;
  --color-neutral-550-rgb: 117, 118, 122;
  --color-neutral-500-rgb: 107, 108, 113;
  --color-neutral-450-rgb: 98, 99, 104;
  --color-neutral-400-rgb: 89, 90, 94;
  --color-neutral-350-rgb: 80, 81, 85;
  --color-neutral-300-rgb: 70, 71, 76;
  --color-neutral-250-rgb: 52, 53, 57;
  --color-neutral-200-rgb: 47, 48, 52;
  --color-neutral-150-rgb: 42, 43, 48;
  --color-neutral-100-rgb: 33, 34, 39;
  --color-neutral-50-rgb: 24, 25, 30;
  --color-neutral-0-rgb: 15, 18, 21;

  --color-neutral-900-hsl: 240deg, 16%, 96%;
  --color-neutral-850-hsl: 240deg, 7%, 92%;
  --color-neutral-800-hsl: 240deg, 3%, 79%;
  --color-neutral-750-hsl: 240deg, 2%, 75%;
  --color-neutral-700-hsl: 240deg, 2%, 68%;
  --color-neutral-650-hsl: 228deg, 2%, 54%;
  --color-neutral-600-hsl: 228deg, 2%, 50%;
  --color-neutral-550-hsl: 228deg, 2%, 47%;
  --color-neutral-500-hsl: 230deg, 3%, 43%;
  --color-neutral-450-hsl: 230deg, 3%, 40%;
  --color-neutral-400-hsl: 228deg, 3%, 36%;
  --color-neutral-350-hsl: 228deg, 3%, 32%;
  --color-neutral-300-hsl: 230deg, 4%, 29%;
  --color-neutral-250-hsl: 228deg, 5%, 21%;
  --color-neutral-200-hsl: 228deg, 5%, 19%;
  --color-neutral-150-hsl: 230deg, 7%, 18%;
  --color-neutral-100-hsl: 230deg, 8%, 14%;
  --color-neutral-50-hsl: 230deg, 11%, 11%;
  --color-neutral-0-hsl: 210deg, 17%, 7%;

  /* Extended Red */
  --color-red-900: #ffddd4;
  --color-red-850: #ffcabd;
  --color-red-800: #ffa58f;
  --color-red-700: #ff9075;
  --color-red-600: #ff7859;
  --color-red-500: #e55d3c;
  --color-red-400: #cc5133;
  --color-red-300: #ad452b;
  --color-red-200: #8f3924;
  --color-red-100: #702d1c;
  --color-red-50: #522014;

  --color-red-900-rgb: 255, 221, 212;
  --color-red-850-rgb: 255, 202, 189;
  --color-red-800-rgb: 255, 165, 143;
  --color-red-700-rgb: 255, 144, 117;
  --color-red-600-rgb: 255, 120, 89;
  --color-red-500-rgb: 229, 93, 60;
  --color-red-400-rgb: 204, 81, 51;
  --color-red-300-rgb: 173, 69, 43;
  --color-red-200-rgb: 143, 57, 36;
  --color-red-100-rgb: 112, 45, 28;
  --color-red-50-rgb: 82, 32, 20;

  --color-red-900-hsl: 13deg, 100%, 92%;
  --color-red-850-hsl: 12deg, 100%, 87%;
  --color-red-800-hsl: 12deg, 100%, 78%;
  --color-red-700-hsl: 12deg, 100%, 73%;
  --color-red-600-hsl: 11deg, 100%, 67%;
  --color-red-500-hsl: 12deg, 76%, 57%;
  --color-red-400-hsl: 12deg, 60%, 50%;
  --color-red-300-hsl: 12deg, 60%, 42%;
  --color-red-200-hsl: 12deg, 60%, 35%;
  --color-red-100-hsl: 12deg, 60%, 27%;
  --color-red-50-hsl: 12deg, 61%, 20%;

  /* Extended Orange */
  --color-orange-900: #fff5eb;
  --color-orange-850: #ffdbbd;
  --color-orange-800: #ffc38f;
  --color-orange-700: #ffa461;
  --color-orange-600: #ff8d4d;
  --color-orange-500: #f68533;
  --color-orange-400: #e98733;
  --color-orange-300: #c6732b;
  --color-orange-200: #a35f24;
  --color-orange-100: #804a1c;
  --color-orange-50: #5d3614;

  --color-orange-900-rgb: 255, 245, 235;
  --color-orange-850-rgb: 255, 219, 189;
  --color-orange-800-rgb: 255, 195, 143;
  --color-orange-700-rgb: 255, 164, 97;
  --color-orange-600-rgb: 255, 141, 77;
  --color-orange-500-rgb: 246, 133, 51;
  --color-orange-400-rgb: 233, 135, 51;
  --color-orange-300-rgb: 198, 115, 43;
  --color-orange-200-rgb: 163, 95, 36;
  --color-orange-100-rgb: 128, 74, 28;
  --color-orange-50-rgb: 93, 54, 20;

  --color-orange-900-hsl: 30deg, 100%, 96%;
  --color-orange-850-hsl: 27deg, 100%, 87%;
  --color-orange-800-hsl: 28deg, 100%, 78%;
  --color-orange-700-hsl: 25deg, 100%, 69%;
  --color-orange-600-hsl: 22deg, 100%, 65%;
  --color-orange-500-hsl: 25deg, 92%, 58%;
  --color-orange-400-hsl: 28deg, 81%, 56%;
  --color-orange-300-hsl: 28deg, 64%, 47%;
  --color-orange-200-hsl: 28deg, 64%, 39%;
  --color-orange-100-hsl: 28deg, 64%, 31%;
  --color-orange-50-hsl: 28deg, 65%, 22%;

  /* Extended Yellow */
  --color-yellow-900: #fffae6;
  --color-yellow-850: #fff3c2;
  --color-yellow-800: #ffe999;
  --color-yellow-700: #ffd033;
  --color-yellow-600: #ffbc33;
  --color-yellow-500: #ffad4c;
  --color-yellow-400: #ffa233;
  --color-yellow-300: #d98a2b;
  --color-yellow-200: #b37124;
  --color-yellow-100: #8c591c;
  --color-yellow-50: #664114;

  --color-yellow-900-rgb: 255, 250, 230;
  --color-yellow-850-rgb: 255, 243, 194;
  --color-yellow-800-rgb: 255, 233, 153;
  --color-yellow-700-rgb: 255, 208, 51;
  --color-yellow-600-rgb: 255, 188, 51;
  --color-yellow-500-rgb: 255, 173, 76;
  --color-yellow-400-rgb: 255, 162, 51;
  --color-yellow-300-rgb: 217, 138, 43;
  --color-yellow-200-rgb: 179, 113, 36;
  --color-yellow-100-rgb: 140, 89, 28;
  --color-yellow-50-rgb: 102, 65, 20;

  --color-yellow-900-hsl: 48deg, 100%, 95%;
  --color-yellow-850-hsl: 48deg, 100%, 88%;
  --color-yellow-800-hsl: 47deg, 100%, 80%;
  --color-yellow-700-hsl: 46deg, 100%, 60%;
  --color-yellow-600-hsl: 40deg, 100%, 60%;
  --color-yellow-500-hsl: 33deg, 100%, 65%;
  --color-yellow-400-hsl: 33deg, 100%, 60%;
  --color-yellow-300-hsl: 33deg, 70%, 51%;
  --color-yellow-200-hsl: 32deg, 67%, 42%;
  --color-yellow-100-hsl: 33deg, 67%, 33%;
  --color-yellow-50-hsl: 33deg, 67%, 24%;

  /* Extended Citron */
  --color-citron-900: #fffbe0;
  --color-citron-850: #fff7c6;
  --color-citron-800: #fff2a0;
  --color-citron-700: #ffeb7f;
  --color-citron-600: #ffe840;
  --color-citron-500: #ffe240;
  --color-citron-400: #ffd540;
  --color-citron-300: #d9b536;
  --color-citron-200: #b3952d;
  --color-citron-100: #8c7523;
  --color-citron-50: #66551a;

  --color-citron-900-rgb: 255, 251, 224;
  --color-citron-850-rgb: 255, 247, 198;
  --color-citron-800-rgb: 255, 242, 160;
  --color-citron-700-rgb: 255, 235, 127;
  --color-citron-600-rgb: 255, 232, 64;
  --color-citron-500-rgb: 255, 226, 64;
  --color-citron-400-rgb: 255, 213, 64;
  --color-citron-300-rgb: 217, 181, 54;
  --color-citron-200-rgb: 179, 149, 45;
  --color-citron-100-rgb: 140, 117, 35;
  --color-citron-50-rgb: 102, 85, 26;

  --color-citron-900-hsl: 52deg, 100%, 94%;
  --color-citron-850-hsl: 52deg, 100%, 89%;
  --color-citron-800-hsl: 52deg, 100%, 81%;
  --color-citron-700-hsl: 51deg, 100%, 75%;
  --color-citron-600-hsl: 53deg, 100%, 63%;
  --color-citron-500-hsl: 51deg, 100%, 63%;
  --color-citron-400-hsl: 47deg, 100%, 63%;
  --color-citron-300-hsl: 47deg, 68%, 53%;
  --color-citron-200-hsl: 47deg, 60%, 44%;
  --color-citron-100-hsl: 47deg, 60%, 34%;
  --color-citron-50-hsl: 47deg, 59%, 25%;

  /* Extended Citron Green */
  --color-citron-green-900: #f8ffe6;
  --color-citron-green-850: #f0f7c0;
  --color-citron-green-800: #eef59a;
  --color-citron-green-700: #e2eb74;
  --color-citron-green-600: #d2dc40;
  --color-citron-green-500: #bfca40;
  --color-citron-green-400: #a2b240;
  --color-citron-green-300: #8a9736;
  --color-citron-green-200: #717d2d;
  --color-citron-green-100: #596223;
  --color-citron-green-50: #41471a;

  --color-citron-green-900-rgb: 248, 255, 230;
  --color-citron-green-850-rgb: 240, 247, 192;
  --color-citron-green-800-rgb: 238, 245, 154;
  --color-citron-green-700-rgb: 226, 235, 116;
  --color-citron-green-600-rgb: 210, 220, 64;
  --color-citron-green-500-rgb: 191, 202, 64;
  --color-citron-green-400-rgb: 162, 178, 64;
  --color-citron-green-300-rgb: 138, 151, 54;
  --color-citron-green-200-rgb: 113, 125, 45;
  --color-citron-green-100-rgb: 89, 98, 35;
  --color-citron-green-50-rgb: 65, 71, 26;

  --color-citron-green-900-hsl: 77deg, 100%, 95%;
  --color-citron-green-850-hsl: 68deg, 77%, 86%;
  --color-citron-green-800-hsl: 65deg, 82%, 78%;
  --color-citron-green-700-hsl: 65deg, 75%, 69%;
  --color-citron-green-600-hsl: 64deg, 69%, 56%;
  --color-citron-green-500-hsl: 65deg, 57%, 52%;
  --color-citron-green-400-hsl: 68deg, 47%, 47%;
  --color-citron-green-300-hsl: 68deg, 47%, 40%;
  --color-citron-green-200-hsl: 69deg, 47%, 33%;
  --color-citron-green-100-hsl: 69deg, 47%, 26%;
  --color-citron-green-50-hsl: 68deg, 46%, 19%;

  /* Extended Green Yellow */
  --color-green-yellow-900: #f1ffe7;
  --color-green-yellow-850: #cff7bc;
  --color-green-yellow-800: #bef594;
  --color-green-yellow-700: #a4ea78;
  --color-green-yellow-600: #92d143;
  --color-green-yellow-500: #7bb433;
  --color-green-yellow-400: #718e33;
  --color-green-yellow-300: #60792b;
  --color-green-yellow-200: #4f6324;
  --color-green-yellow-100: #3e4e1c;
  --color-green-yellow-50: #2d3914;

  --color-green-yellow-900-rgb: 241, 255, 231;
  --color-green-yellow-850-rgb: 207, 247, 188;
  --color-green-yellow-800-rgb: 190, 245, 148;
  --color-green-yellow-700-rgb: 164, 234, 120;
  --color-green-yellow-600-rgb: 146, 209, 67;
  --color-green-yellow-500-rgb: 123, 180, 51;
  --color-green-yellow-400-rgb: 113, 142, 51;
  --color-green-yellow-300-rgb: 96, 121, 43;
  --color-green-yellow-200-rgb: 79, 99, 36;
  --color-green-yellow-100-rgb: 62, 78, 28;
  --color-green-yellow-50-rgb: 45, 57, 20;

  --color-green-yellow-900-hsl: 95deg, 100%, 95%;
  --color-green-yellow-850-hsl: 101deg, 79%, 85%;
  --color-green-yellow-800-hsl: 94deg, 83%, 77%;
  --color-green-yellow-700-hsl: 97deg, 73%, 69%;
  --color-green-yellow-600-hsl: 87deg, 61%, 54%;
  --color-green-yellow-500-hsl: 87deg, 56%, 45%;
  --color-green-yellow-400-hsl: 79deg, 47%, 38%;
  --color-green-yellow-300-hsl: 79deg, 48%, 32%;
  --color-green-yellow-200-hsl: 79deg, 47%, 26%;
  --color-green-yellow-100-hsl: 79deg, 47%, 21%;
  --color-green-yellow-50-hsl: 79deg, 48%, 15%;

  /* Extended Green */
  --color-green-900: #e9fdf2;
  --color-green-850: #bcf7da;
  --color-green-800: #94f5cd;
  --color-green-700: #79e1b5;
  --color-green-600: #5ec298;
  --color-green-500: #339f7b;
  --color-green-400: #338569;
  --color-green-300: #2b7159;
  --color-green-200: #245d4a;
  --color-green-100: #1c493a;
  --color-green-50: #14352a;

  --color-green-900-rgb: 233, 253, 242;
  --color-green-850-rgb: 188, 247, 218;
  --color-green-800-rgb: 148, 245, 205;
  --color-green-700-rgb: 121, 225, 181;
  --color-green-600-rgb: 94, 194, 152;
  --color-green-500-rgb: 51, 159, 123;
  --color-green-400-rgb: 51, 133, 105;
  --color-green-300-rgb: 43, 113, 89;
  --color-green-200-rgb: 36, 93, 74;
  --color-green-100-rgb: 28, 73, 58;
  --color-green-50-rgb: 20, 53, 42;

  --color-green-900-hsl: 147deg, 83%, 95%;
  --color-green-850-hsl: 151deg, 79%, 85%;
  --color-green-800-hsl: 155deg, 83%, 77%;
  --color-green-700-hsl: 155deg, 63%, 68%;
  --color-green-600-hsl: 155deg, 45%, 56%;
  --color-green-500-hsl: 160deg, 51%, 41%;
  --color-green-400-hsl: 160deg, 45%, 36%;
  --color-green-300-hsl: 159deg, 45%, 31%;
  --color-green-200-hsl: 160deg, 44%, 25%;
  --color-green-100-hsl: 160deg, 45%, 20%;
  --color-green-50-hsl: 160deg, 45%, 14%;

  /* Extended Teal */
  --color-teal-900: #ecfdff;
  --color-teal-850: #c6f7ff;
  --color-teal-800: #9ae9f5;
  --color-teal-700: #40d5ec;
  --color-teal-600: #40cae3;
  --color-teal-500: #40bacf;
  --color-teal-400: #40aabc;
  --color-teal-300: #3691a0;
  --color-teal-200: #2d7784;
  --color-teal-100: #235e67;
  --color-teal-50: #1a444b;

  --color-teal-900-rgb: 236, 253, 255;
  --color-teal-850-rgb: 198, 247, 255;
  --color-teal-800-rgb: 154, 233, 245;
  --color-teal-700-rgb: 64, 213, 236;
  --color-teal-600-rgb: 64, 202, 227;
  --color-teal-500-rgb: 64, 186, 207;
  --color-teal-400-rgb: 64, 170, 188;
  --color-teal-300-rgb: 54, 145, 160;
  --color-teal-200-rgb: 45, 119, 132;
  --color-teal-100-rgb: 35, 94, 103;
  --color-teal-50-rgb: 26, 68, 75;

  --color-teal-900-hsl: 186deg, 100%, 96%;
  --color-teal-850-hsl: 188deg, 100%, 89%;
  --color-teal-800-hsl: 188deg, 82%, 78%;
  --color-teal-700-hsl: 188deg, 82%, 59%;
  --color-teal-600-hsl: 189deg, 74%, 57%;
  --color-teal-500-hsl: 189deg, 60%, 53%;
  --color-teal-400-hsl: 189deg, 49%, 49%;
  --color-teal-300-hsl: 188deg, 50%, 42%;
  --color-teal-200-hsl: 189deg, 49%, 35%;
  --color-teal-100-hsl: 188deg, 49%, 27%;
  --color-teal-50-hsl: 189deg, 49%, 20%;

  /* Extended Cyan */
  --color-cyan-900: #e3f2ff;
  --color-cyan-850: #bee5ff;
  --color-cyan-800: #67bfff;
  --color-cyan-700: #47aeff;
  --color-cyan-600: #26a2ff;
  --color-cyan-500: #2688d4;
  --color-cyan-400: #2c78b3;
  --color-cyan-300: #256698;
  --color-cyan-200: #1f547d;
  --color-cyan-100: #184262;
  --color-cyan-50: #123048;

  --color-cyan-900-rgb: 227, 242, 255;
  --color-cyan-850-rgb: 190, 229, 255;
  --color-cyan-800-rgb: 103, 191, 255;
  --color-cyan-700-rgb: 71, 174, 255;
  --color-cyan-600-rgb: 38, 162, 255;
  --color-cyan-500-rgb: 38, 136, 212;
  --color-cyan-400-rgb: 44, 120, 179;
  --color-cyan-300-rgb: 37, 102, 152;
  --color-cyan-200-rgb: 31, 84, 125;
  --color-cyan-100-rgb: 24, 66, 98;
  --color-cyan-50-rgb: 18, 48, 72;

  --color-cyan-900-hsl: 208deg, 100%, 95%;
  --color-cyan-850-hsl: 204deg, 100%, 87%;
  --color-cyan-800-hsl: 205deg, 100%, 70%;
  --color-cyan-700-hsl: 206deg, 100%, 64%;
  --color-cyan-600-hsl: 206deg, 100%, 57%;
  --color-cyan-500-hsl: 206deg, 70%, 49%;
  --color-cyan-400-hsl: 206deg, 61%, 44%;
  --color-cyan-300-hsl: 206deg, 61%, 37%;
  --color-cyan-200-hsl: 206deg, 60%, 31%;
  --color-cyan-100-hsl: 206deg, 61%, 24%;
  --color-cyan-50-hsl: 207deg, 60%, 18%;

  /* Extended Blue */
  --color-blue-900: #deebff;
  --color-blue-850: #c2ddff;
  --color-blue-800: #70aeff;
  --color-blue-700: #519dff;
  --color-blue-600: #3384ff;
  --color-blue-500: #3375d6;
  --color-blue-400: #396cb8;
  --color-blue-300: #305c9c;
  --color-blue-200: #284c81;
  --color-blue-100: #1f3b65;
  --color-blue-50: #172b4a;

  --color-blue-900-rgb: 222, 235, 255;
  --color-blue-850-rgb: 194, 221, 255;
  --color-blue-800-rgb: 112, 174, 255;
  --color-blue-700-rgb: 81, 157, 255;
  --color-blue-600-rgb: 51, 132, 255;
  --color-blue-500-rgb: 51, 117, 214;
  --color-blue-400-rgb: 57, 108, 184;
  --color-blue-300-rgb: 48, 92, 156;
  --color-blue-200-rgb: 40, 76, 129;
  --color-blue-100-rgb: 31, 59, 101;
  --color-blue-50-rgb: 23, 43, 74;

  --color-blue-900-hsl: 216deg, 100%, 94%;
  --color-blue-850-hsl: 213deg, 100%, 88%;
  --color-blue-800-hsl: 214deg, 100%, 72%;
  --color-blue-700-hsl: 214deg, 100%, 66%;
  --color-blue-600-hsl: 216deg, 100%, 60%;
  --color-blue-500-hsl: 216deg, 67%, 52%;
  --color-blue-400-hsl: 216deg, 53%, 47%;
  --color-blue-300-hsl: 216deg, 53%, 40%;
  --color-blue-200-hsl: 216deg, 53%, 33%;
  --color-blue-100-hsl: 216deg, 53%, 26%;
  --color-blue-50-hsl: 216deg, 53%, 19%;

  /* Extended Violet */
  --color-violet-900: #d9e4ff;
  --color-violet-850: #acb8f3;
  --color-violet-800: #92a0fa;
  --color-violet-700: #717ade;
  --color-violet-600: #6367d5;
  --color-violet-500: #575eca;
  --color-violet-400: #4750ab;
  --color-violet-300: #3c4491;
  --color-violet-200: #323878;
  --color-violet-100: #272c5e;
  --color-violet-50: #1c2044;

  --color-violet-900-rgb: 217, 228, 255;
  --color-violet-850-rgb: 172, 184, 243;
  --color-violet-800-rgb: 146, 160, 250;
  --color-violet-700-rgb: 113, 122, 222;
  --color-violet-600-rgb: 99, 103, 213;
  --color-violet-500-rgb: 87, 94, 202;
  --color-violet-400-rgb: 71, 80, 171;
  --color-violet-300-rgb: 60, 68, 145;
  --color-violet-200-rgb: 50, 56, 120;
  --color-violet-100-rgb: 39, 44, 94;
  --color-violet-50-rgb: 28, 32, 68;

  --color-violet-900-hsl: 223deg, 100%, 93%;
  --color-violet-850-hsl: 230deg, 75%, 81%;
  --color-violet-800-hsl: 232deg, 91%, 78%;
  --color-violet-700-hsl: 235deg, 62%, 66%;
  --color-violet-600-hsl: 238deg, 58%, 61%;
  --color-violet-500-hsl: 236deg, 52%, 57%;
  --color-violet-400-hsl: 235deg, 41%, 47%;
  --color-violet-300-hsl: 234deg, 41%, 40%;
  --color-violet-200-hsl: 235deg, 41%, 33%;
  --color-violet-100-hsl: 235deg, 41%, 26%;
  --color-violet-50-hsl: 234deg, 42%, 19%;

  /* Extended Purple */
  --color-purple-900: #dbd5f6;
  --color-purple-850: #b4a6fa;
  --color-purple-800: #9f8efB;
  --color-purple-700: #8772f1;
  --color-purple-600: #6e56e6;
  --color-purple-500: #5844bb;
  --color-purple-400: #493ca2;
  --color-purple-300: #3a3489;
  --color-purple-200: #322e6d;
  --color-purple-100: #2a2750;
  --color-purple-50: #222134;

  --color-purple-900-rgb: 219, 213, 246;
  --color-purple-850-rgb: 180, 166, 250;
  --color-purple-800-rgb: 159, 142, 251;
  --color-purple-700-rgb: 135, 114, 241;
  --color-purple-600-rgb: 110, 86, 230;
  --color-purple-500-rgb: 88, 68, 187;
  --color-purple-400-rgb: 73, 60, 162;
  --color-purple-300-rgb: 58, 52, 137;
  --color-purple-200-rgb: 50, 46, 109;
  --color-purple-100-rgb: 42, 39, 80;
  --color-purple-50-rgb: 34, 33, 52;

  --color-purple-900-hsl: 251deg, 65%, 90%;
  --color-purple-850-hsl: 250deg, 89%, 82%;
  --color-purple-800-hsl: 249deg, 93%, 77%;
  --color-purple-700-hsl: 250deg, 82%, 70%;
  --color-purple-600-hsl: 250deg, 74%, 62%;
  --color-purple-500-hsl: 250deg, 47%, 50%;
  --color-purple-400-hsl: 248deg, 46%, 44%;
  --color-purple-300-hsl: 244deg, 45%, 37%;
  --color-purple-200-hsl: 244deg, 41%, 30%;
  --color-purple-100-hsl: 244deg, 34%, 23%;
  --color-purple-50-hsl: 243deg, 22%, 17%;

  /* Extended Purple Red */
  --color-purple-red-900: #f0dff9;
  --color-purple-red-850: #d8b1ea;
  --color-purple-red-800: #b58dd5;
  --color-purple-red-700: #a472c2;
  --color-purple-red-600: #945fb4;
  --color-purple-red-500: #8555b4;
  --color-purple-red-400: #764b99;
  --color-purple-red-300: #644082;
  --color-purple-red-200: #53356b;
  --color-purple-red-100: #412954;
  --color-purple-red-50: #2f1e3d;

  --color-purple-red-900-rgb: 240, 223, 249;
  --color-purple-red-850-rgb: 216, 177, 234;
  --color-purple-red-800-rgb: 181, 141, 213;
  --color-purple-red-700-rgb: 164, 114, 194;
  --color-purple-red-600-rgb: 148, 95, 180;
  --color-purple-red-500-rgb: 133, 85, 180;
  --color-purple-red-400-rgb: 118, 75, 153;
  --color-purple-red-300-rgb: 100, 64, 130;
  --color-purple-red-200-rgb: 83, 53, 107;
  --color-purple-red-100-rgb: 65, 41, 84;
  --color-purple-red-50-rgb: 47, 30, 61;

  --color-purple-red-900-hsl: 279deg, 68%, 93%;
  --color-purple-red-850-hsl: 281deg, 58%, 81%;
  --color-purple-red-800-hsl: 273deg, 46%, 69%;
  --color-purple-red-700-hsl: 278deg, 40%, 60%;
  --color-purple-red-600-hsl: 277deg, 36%, 54%;
  --color-purple-red-500-hsl: 270deg, 39%, 52%;
  --color-purple-red-400-hsl: 273deg, 34%, 45%;
  --color-purple-red-300-hsl: 273deg, 34%, 38%;
  --color-purple-red-200-hsl: 273deg, 34%, 31%;
  --color-purple-red-100-hsl: 273deg, 34%, 25%;
  --color-purple-red-50-hsl: 273deg, 34%, 18%;

  /* Extended Pink */
  --color-pink-900: #ffebfa;
  --color-pink-850: #ffbde5;
  --color-pink-800: #ffa3e6;
  --color-pink-700: #ff7fd0;
  --color-pink-600: #f363c7;
  --color-pink-500: #e55dae;
  --color-pink-400: #cc51a9;
  --color-pink-300: #ad4590;
  --color-pink-200: #8f3976;
  --color-pink-100: #702d5d;
  --color-pink-50: #522044;

  --color-pink-900-rgb: 255, 235, 250;
  --color-pink-850-rgb: 255, 189, 229;
  --color-pink-800-rgb: 255, 163, 230;
  --color-pink-700-rgb: 255, 127, 208;
  --color-pink-600-rgb: 243, 99, 199;
  --color-pink-500-rgb: 229, 93, 174;
  --color-pink-400-rgb: 204, 81, 169;
  --color-pink-300-rgb: 173, 69, 144;
  --color-pink-200-rgb: 143, 57, 118;
  --color-pink-100-rgb: 112, 45, 93;
  --color-pink-50-rgb: 82, 32, 68;

  --color-pink-900-hsl: 315deg, 100%, 96%;
  --color-pink-850-hsl: 324deg, 100%, 87%;
  --color-pink-800-hsl: 316deg, 100%, 82%;
  --color-pink-700-hsl: 322deg, 100%, 75%;
  --color-pink-600-hsl: 318deg, 86%, 67%;
  --color-pink-500-hsl: 324deg, 72%, 63%;
  --color-pink-400-hsl: 317deg, 55%, 56%;
  --color-pink-300-hsl: 317deg, 43%, 47%;
  --color-pink-200-hsl: 317deg, 43%, 39%;
  --color-pink-100-hsl: 317deg, 43%, 31%;
  --color-pink-50-hsl: 317deg, 44%, 22%;

  --color-primary-cta: var(--color-purple-600);
  --color-primary-cta-rgb: var(--color-purple-600-rgb);
  --color-primary-cta-hsl: var(--color-purple-600-hsl);

  --color-primary-interactive: var(--color-purple-800);
  --color-primary-interactive-rgb: var(--color-purple-800-rgb);
  --color-primary-interactive-hsl: var(--color-purple-800-hsl);

  --color-error-message-text: var(--color-red-600);
  --color-error-message-text-rgb: var(--color-red-600-rgb);
  --color-error-message-text-hsl: var(--color-red-600-hsl);

  --color-high-emphasis-text: var(--color-neutral-900);
  --color-high-emphasis-text-rgb: var(--color-neutral-900-rgb);
  --color-high-emphasis-text-hsl: var(--color-neutral-900-hsl);

  --color-medium-emphasis-text: var(--color-neutral-850);
  --color-medium-emphasis-text-rgb: var(--color-neutral-850-rgb);
  --color-medium-emphasis-text-hsl: var(--color-neutral-850-hsl);

  --color-low-emphasis-text: var(--color-neutral-800);
  --color-low-emphasis-text-rgb: var(--color-neutral-800-rgb);
  --color-low-emphasis-text-hsl: var(--color-neutral-800-hsl);

  --color-high-emphasis-border: var(--color-neutral-750);
  --color-high-emphasis-border-rgb: var(--color-neutral-750-rgb);
  --color-high-emphasis-border-hsl: var(--color-neutral-750-hsl);

  --color-input-placeholder-text: var(--color-neutral-650);
  --color-input-placeholder-text-rgb: var(--color-neutral-650-rgb);
  --color-input-placeholder-text-hsl: var(--color-neutral-650-hsl);

  --color-secondary-button-background: var(--color-neutral-550);
  --color-secondary-button-background-rgb: var(--color-neutral-550-rgb);
  --color-secondary-button-background-hsl: var(--color-neutral-550-hsl);

  --color-disabled-type: var(--color-neutral-500);
  --color-disabled-type-rgb: var(--color-neutral-500-rgb);
  --color-disabled-type-hsl: var(--color-neutral-500-hsl);

  --color-surface-light1: var(--color-neutral-350);
  --color-surface-light1-rgb: var(--color-neutral-350-rgb);
  --color-surface-light1-hsl: var(--color-neutral-350-hsl);

  --color-surface-light2: var(--color-neutral-300);
  --color-surface-light2-rgb: var(--color-neutral-300-rgb);
  --color-surface-light2-hsl: var(--color-neutral-300-hsl);

  --color-surface-medium1: var(--color-neutral-250);
  --color-surface-medium1-rgb: var(--color-neutral-250-rgb);
  --color-surface-medium1-hsl: var(--color-neutral-250-hsl);

  --color-surface-medium2: var(--color-neutral-200);
  --color-surface-medium2-rgb: var(--color-neutral-200-rgb);
  --color-surface-medium2-hsl: var(--color-neutral-200-hsl);

  --color-surface-dark1: var(--color-neutral-150);
  --color-surface-dark1-rgb: var(--color-neutral-150-rgb);
  --color-surface-dark1-hsl: var(--color-neutral-150-hsl);

  --color-surface-dark2: var(--color-neutral-100);
  --color-surface-dark2-rgb: var(--color-neutral-100-rgb);
  --color-surface-dark2-hsl: var(--color-neutral-100-hsl);

  --color-surface-dark3: var(--color-neutral-50);
  --color-surface-dark3-rgb: var(--color-neutral-50-rgb);
  --color-surface-dark3-hsl: var(--color-neutral-50-hsl);

  --color-surface-background: var(--color-neutral-0);
  --color-surface-background-rgb: var(--color-neutral-0-rgb);
  --color-surface-background-hsl: var(--color-neutral-0-hsl);


  // Props panel rows should be separated by 8px
  --layout-props-panel-row-separation: 8px;
}
