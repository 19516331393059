/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.navContentDialog {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 36px;

  // The 90vh matches the 90% max-height of the modal. The 166px is roughly
  // the sum of all padding, heading & footer (the action buttons) outside of
  // the actual content and the 45px is what would be a small optional footer.
  // Ideally, this should be calculated dynamically.
  --content-max-height: calc(90vh - 166px - 45px);

  & > nav {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 4px;

    // When we scroll the nav on the left, we don't want that to affect the
    // content on the right. That's why we should add scroll to the nav.
    max-height: var(--content-max-height);
    overflow: auto;

    & > button {
      --font-weight: 400;
      --bg-color: transparent;

      &.active {
        --font-weight: 600;
        --bg-color: var(--color-surface-medium1);
      }

      display: flex;
      flex-direction: column;
      gap: 12px;
      background-color: var(--bg-color);
      border: 0;
      outline: 0;
      padding: 8px 12px;
      margin: 0;
      border-radius: 10px;
      font-size: 14px;
      line-height: 20px;
      font-weight: var(--font-weight);
      color: var(--color-high-emphasis-text);
      transition: background-color 500ms;
      text-align: left;
      cursor: pointer;

      & > .subitems {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-left: 20px;
        font-weight: normal;
      }
    }
  }

  & > .sections {
    flex: 1 1 auto;
    // Add top padding to match the nav button padding, so that the description
    // text and first nav button text are baseline-aligned.  Using
    // align-items:baseline on the root element doesn't quite work because of
    // the two consecutive Collapsibles.
    padding-top: 8px;
    // When we scroll the content on the right, we don't want that to affect the
    // nav on the right. That's why we should add scroll to the content area.
    max-height: var(--content-max-height);
    overflow: auto;

    &:not(.collapsible) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }

    & section {
      display: flex;
      flex-direction: column;
      align-items: stretch;
      gap: 20px;

      & > .description {
        color: var(--color-medium-emphasis-text);
      }

      & > .tableSection {
        display: flex;
        flex-direction: column;
        gap: 8px;

        & > .tableLabel {
          color: var(--color-low-emphasis-text);
          font-size: 14px;
          font-weight: 600;
          line-height: 20px;
        }

        & > .table {
          display: flex;
          flex-direction: column;
          gap: 1px;

          & > .tableHeading {
            color: var(--color-low-emphasis-text);
            background-color: var(--color-neutral-150);
            font-weight: 600;
            height: 28px;
            line-height: 28px;
            padding: 0px 12px;
          }

          & > .rows {
            display: flex;
            flex-direction: column;
            gap: 1px;
            line-height: 16px;

            & > .row {
              display: flex;
              padding: 8px 0;
              color: var(--color-medium-emphasis-text);
              background-color: var(--color-neutral-50);

              & > .column {
                padding: 0 12px;

                &:first-of-type {
                  width: 224px;
                  min-width: 224px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }

                &:not(:first-of-type) {
                  flex: 1;
                }

                & > .quantity {
                  display: flex;
                  gap: 8px;

                  & > .unit {
                    color: var(--color-neutral-650);
                  }
                }

                & > .link {
                  display: block;
                  padding: 0;
                  background: none;
                  border: none;
                  color: var(--color-high-emphasis-text);
                  text-decoration: underline;
                  cursor: pointer;
                  font-size: inherit;

                  &:not(:first-child) {
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
