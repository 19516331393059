/* Copyright 2023 Luminary Cloud, Inc. All Rights Reserved. */
.propellerSpinner {
  @keyframes spin {
    0% {
      transform: rotateY(180deg) rotate(0deg);
    }

    100% {
      transform: rotateY(180deg) rotate(-360deg);
    }
  }

  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: var(--size);
  height: var(--size);
  animation-name: spin;
  animation-duration: 1000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
