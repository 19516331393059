/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.columnOnlyContainer {
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 6px;
  width: 100%;

  background-color: var(--color-neutral-100);
  padding: 6px 8px;
  border-radius: 4px;
  box-shadow: 0px -1px 0px 0px rgba(70, 71, 76, 0.50) inset;

  & button {
    color: var(--color-neutral-650);
    background-color: inherit;
    padding: 0;
    margin: 0;
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }

  & .column {
    --icon-color: var(--color-neutral-650);

    &:hover {
      --icon-color: var(--color-neutral-900);
    }

    display: flex;
    flex: 1;
    flex-direction: row;
    gap: 6px;
    justify-content: space-between;
    align-items: center;

    overflow: hidden;
    white-space: nowrap;
    text-wrap: nowrap;

    & .name {
      color: var(--color-neutral-900);
      font-size: 13px;
      line-height: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 4px;

      overflow: hidden;
      white-space: nowrap;
      text-wrap: nowrap;
      text-overflow: ellipsis;

      & .icon {
        align-self: baseline;
        color: var(--icon-color);
        width: 12px;
        height: 12px
      }
    }

  }

  & .unlink {
    height: 100%;
    padding: 0;
    margin: 0;

    &:hover {
      color: var(--color-neutral-900);
    }
  }
}