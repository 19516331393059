/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */
.autoCollapsingMessage {
  --color: var(--color-low-emphasis-text);

  &.error {
    --color: var(--color-error-message-text);
  }

  &.warning {
    --color: var(--color-yellow-500);
  }

  &.success {
    --color: var(--color-green-500);
  }

  &.info {
    --color: var(--color-primary-cta);
  }

  & .message {
    font-size: 13px;
    font-weight: 400;
    color: var(--color);
    transition: color 500ms;
  }

  & .centered {
    text-align: center;
  }
}
