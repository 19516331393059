/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */

/*
  Styles according to the  "_Radio base" section in
  https://www.figma.com/file/jgngRpKr9HPeHuI8hKQfbS/Luminary-Component-Library-V2?type=design&node-id=64-34642
 */

.radioButton {
  --cursor: auto;
  --display-bg-color: var(--color-neutral-350);
  // box-shadow acts as a border here
  --box-shadow: inset 0 0 0 1px var(--color-neutral-400);
  --indicator-color: var(--color-neutral-700);
  --indicator-opacity: 0;

  &.enabled {
    --cursor: pointer;
    --display-bg-color: var(--color-neutral-50);
    // box-shadow acts as border here
    --box-shadow: inset 0 0 0 1px var(--color-neutral-150);
    --indicator-color: var(--color-neutral-900);

    &:hover {
      --display-bg-color: var(--color-neutral-200);

      &:not(.checked) {
        // box-shadow is an actual inset bottom shadow
        --box-shadow: inset 0px -1px 0px rgb(255, 255, 255, 0.25);
      }
    }

    &:active {
      --display-bg-color: var(--color-neutral-250);
    }

    &.checked {
      --display-bg-color: var(--color-primary-cta);
      --box-shadow: none;

      &:hover {
        --display-bg-color: var(--color-purple-500);
      }

      &:active {
        --display-bg-color: var(--color-purple-400);
      }
    }

    &:focus,
    &:focus-within {
      &:not(:active) {
        // box-shadow acts as a border here
        --box-shadow: inset 0 0 0 1px var(--color-primary-interactive);
      }
    }
  }

  &.checked {
    --indicator-opacity: 1;
  }

  display: inline-flex;
  position: relative;
  overflow: hidden;
  cursor: var(--cursor);
  border-radius: 50%;
  outline: 0;

  & > .input {
    position: absolute;
    height: 0;
    width: 0;
    overflow: hidden;
  }

  & > .display {
    position: relative;
    display: inline-flex;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--display-bg-color);
    box-shadow: var(--box-shadow);
    transition: background-color 250ms, box-shadow 250ms;

    &::after {
      content: '';
      position: absolute;
      top: 25%;
      bottom: 25%;
      left: 25%;
      right: 25%;
      border-radius: 50%;
      background-color: var(--indicator-color);
      opacity: var(--indicator-opacity);
      transition: opacity 250ms, background-color 250ms;
    }
  }
}
