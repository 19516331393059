/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */
.dialogBase {
  --frame-max-extent: auto;
  --frame-shadow: 0 0 25px 10px rgba(0, 0, 0, 0.5);

  &.modal {
    --frame-max-extent: 90%;
    // A frame shadow gives the modal depth; it also helps differentiate
    // multiple, stacked modal dialogs
  }

  & {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    overflow: hidden;
    border-radius: 8px;
    max-width: var(--frame-max-extent);
    max-height: var(--frame-max-extent);
    background-color: var(--color-neutral-150);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 0px 1px 0px var(--color-neutral-400),
      0 0 25px 10px rgba(0, 0, 0, 0.5);
    padding: 0;
    --toggle-rotation:0deg;
  }

  &.compact {
    border-radius: 4px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25),
      inset 0px 1px 0px var(--color-neutral-400),
      0 0 15px 4px rgba(0, 0, 0, 0.25);
  }

  & > .dialogBody {
    cursor: auto;
    padding: 24px;
    display: flex;
    justify-content: flex-start;
    align-items: stretch;
    gap: 8px;
    overflow: hidden;

    &.compact {
      // This is not ideal but needed for edge-to-edge divider when used in iGeo
      padding: 0;
    }

    &.draggable {
      cursor: grab;

      &.dragging {
        cursor: grabbing;
      }
    }

    & > .dialogSymbol {
      align-self: flex-start;
      flex: 0 0 auto;
      align-items: center;
    }

    & > form {
      flex: 1 1 auto;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow: hidden;

      & > .dialogHeader {
        flex: 0 0 auto;
        color: var(--color-high-emphasis-text);

        &.compact {
          padding: 14px 12px 8px;

          &.collapsed {
            padding: 14px 12px 12px;
          }
        }

        & > .headerBar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 16px;

          & > .headerTitle {
            flex: 1 1 auto;
            margin: 0;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
            white-space: wrap;

            &.compact {
              font-size: 13px;
              line-height: 16px;
            }
          }

          & > .headerControl {
            opacity: 0.5;
            transition: opacity 250ms;

            &:hover {
              opacity: 1
            }
          }

          & > .toggle {
            &.collapsed {
              --toggle-rotation: -90deg;
            }

            color: var(--header-caret-color);
            flex: 0 0 auto;
            display: inline-flex;
            transform: rotate(var(--toggle-rotation));
            transition: transform 250ms, color 500ms;
            cursor: pointer;
            padding: 4px;
          }
        }

        & > .headerSubtitle {
          margin: 0;
          padding: 8px 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          color: var(--color-low-emphasis-text);
        }
      }

      & .dialogContent {
        flex: 1 1 auto;
        padding: 8px 0 0;
        overflow-y: auto;
        overflow-x: hidden;
        font-size: 14px;
        cursor: default;

        &.compact {
          padding: 14px 12px 8px;
        }

        &.allowOverflow {
          overflow-x: visible;
        }
      }

      & > .dialogFooter {
        font-size: 13px;
        padding-top: 24px;

        &.compact {
          padding: 8px 8px 0;
        }
      }

      & > .dialogActions {
        flex: 0 0 auto;
        padding: 24px 0 0;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        gap: 8px;

        & > .dialogButtonsGap {
          flex: 1;
        }

        &.hasFooter {
          padding-top: 12px;
        }

        &.compact {
          padding: 8px;
        }

        &.draggable {
          cursor: grab;

          &.dragging {
            cursor: grabbing;
          }
        }
      }
    }
  }

  & .dialogProgress {
    padding: 16px 32px;
    background-color: var(--color-surface-light2);

    & > .progressMessage {
      color: var(--color-low-emphasis-text);
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & > .progressBar {
      padding: 4px 0;
    }
  }
}
