/* Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved. */

.vector3Input {
  display: flex;
  gap: 4px;

  &.vertical {
    flex-direction: column;
    align-items: stretch;
  }
}
