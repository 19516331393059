/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */

.searchBox {
  display: flex;
  align-items: center;
  gap: 4px;
  overflow: hidden;

  &.decorated {
    padding: 0 8px;
    height: 28px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--decorated-bg-color);
    box-shadow: inset 0px 1px 4px 0px rgb(var(--color-surface-background-rgb), 0.1),
      inset 0px -1px 0px 0px var(--decorated-highlight-color);
  }

  & button {
    flex: 0 0 auto;
    margin: 0;
    border: 0;
    outline: 0;
    padding: 0;
    background-color: transparent;
    display: flex;
    color: var(--color-high-emphasis-text);

    &.clearButton {
      padding: 3px;
      opacity: 0;
      transition: opacity 250ms;

      &.clearable {
        cursor: pointer;
        opacity: 0.75;

        &:hover {
          opacity: 1;
        }
      }
    }

    &.leadButton {
      cursor: pointer;
    }
  }

  & .main {
    flex: 1 1 auto;
    display: flex;
    align-items: center;
  }

  & input.searchInput {
    background-color: transparent;
    border: 0;
    margin: 0;
    outline: 0;
    box-shadow: none;
    color: var(--color-high-emphasis-text);
    font-size: 12px;
    font-weight: 400;
    padding: 2px;
    width: 100%;


    &::placeholder {
      color: var(--placeholder-color);
    }
  }
}
