/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.buttonTab {
  --bg-color: var(--color-surface-dark2);
  --border-radius-left: 0;
  --border-radius-right: 0;
  --box-shadow: 0 1px 0 rgb(var(--color-neutral-500-rgb), 0.15), inset 0 2px 0 var(--color-surface-medium2);
  --button-height: 36px;
  --cursor: auto;
  --font-size: 14px;
  --text-color: var(--color-low-emphasis-text);

  &.small {
    --button-height: 28px;
    --font-size: 13px;
  }

  &.xsmall {
    --button-height: 28px;
    --font-size: 13px;
  }

  &.selected {
    --bg-color: var(--color-secondary-button-background);
    --box-shadow: 0 1px 0 rgb(var(--color-neutral-500-rgb), 0.15), inset 0 2px 0 var(--color-neutral-650);
    --text-color: var(--color-high-emphasis-text);
  }

  &.first {
    --border-radius-left: 4px;
  }

  &.last {
    --border-radius-right: 4px;
  }

  &.disabled {
    --text-color: var(--color-disabled-type);

    &.selected {
      --text-color: var(--color-low-emphasis-text);
    }
  }

  & {
    &:enabled {
      --cursor: pointer;
    }

    & {
      outline: 0;
      border: 0;
      margin: 0;
      height: var(--button-height);
      padding: 0 2em;
      background-color: var(--bg-color);
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: var(--border-radius-left) var(--border-radius-right) var(--border-radius-right) var(--border-radius-left);
      font-size: var(--font-size);
      cursor: var(--cursor);
      line-height: 1.0;
      font-weight: 600;
      color: var(--text-color);
      box-shadow: var(--box-shadow);
      transition-property: background-color, box-shadow, color;
      transition-duration: 250ms;
      white-space: nowrap;
    }

    &.xsmall {
      padding: 0 1em;
    }
  }
}
