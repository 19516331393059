/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.overlayContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 8px;

  padding: 8px 16px;
  width: 100%;
  height: 100%;

  pointer-events: none;

  & > * {
    pointer-events: auto;
  }
}
