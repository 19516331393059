/* Copyright 2023-2024 Luminary Cloud, Inc. All Rights Reserved. */

.dataTable {
  --border-color: var(--color-neutral-300);
  --highlight-border: 1px solid var(--color-primary-interactive);
  --sort-width: 9px;
  --header-control-button-gap: 8px;

  display: inline-flex;
  flex-direction: column;
  gap: 16px;

  &.asBlock {
    display: flex;
  }

  &:not(.scrollable) > * {
    flex: 0 0 auto;
  }

  &.scrollable {
    height: 100%;

    & > .tableContainer {
      overflow: auto;
    }
  }

  & > .tableContainer {
    & > table {
      border-collapse: collapse;
      border-spacing: 0;
      width: 100%;
      height: 1px; // See note (*)

      & tr {
        --row-control-opacity: 0;

        &:hover {
          --row-control-opacity: 1;
        }

        &:last-child > td.grid {
          border-bottom: none;
        }

        height: 100%; // See note (*)

        &:first-child > th.highlight {
          border-top: var(--highlight-border);
        }

        &:last-child > td.highlight {
          border-bottom: var(--highlight-border);
        }

        & th,
        & td {
          --text-align: start;

          &.alignCenter {
            --text-align: center;
          }

          &.alignEnd {
            --text-align: end;
          }

          &.grid {
            border: 1px solid var(--border-color);

            &:first-child {
              border-left: none;
            }

            &:last-child:not(.highlight) {
              border-right: none;
            }
          }

          padding: 0;
          line-height: 16px;
          color: var(--color-low-emphasis-text);
          font-weight: 400;
          border-bottom: 1px solid var(--border-color);
          transition: color 500ms;
          text-align: var(--text-align);

          &.minimalWidth {
            // Ideally, width: min-content would work here, but it doesn't; so
            // we resort to this decades-old workaround.
            width: 1%;
          }

          &.control {
            position: relative;
            overflow: visible;
            padding: 0;
            width: 16px;

            & > .controlCellMenu {
              position: absolute;
              top: calc(100% - 8px);
              right: 0;
              z-index: 2;
            }
          }

          &.highlight {
            border-right: var(--highlight-border);
          }

          &:has(+ .highlight) {
            // the cell to the left of the highlight cell is rendered over the
            // highlight cell, so the left cell must have border-right
            border-right: var(--highlight-border);
          }

          & > a {
            padding: 0;
            display: inline-block;
            width: 100%;
            height: 100%; // See note (*)
          }

          & .cellContent {

            &.default {
              --padding-density: 16px;
            }

            &.comfortable {
              --padding-density: 10px 12px;
            }

            padding: var(--padding-density);
            position: relative;
            z-index: 1;
          }
        }

        & button.control {
          background-color: transparent;
          color: inherit;
          border: 0;
          outline: 0;
          box-shadow: 0;
          display: inline-flex;
          align-items: flex-end;
          width: 100%;
          gap: var(--header-control-button-gap);
          transition: color 250ms, opacity 250ms, background-color 250ms;

          &:enabled {
            cursor: pointer;
          }

          &:hover {
            color: var(--color-high-emphasis-text);
          }

          &.engaged {
            --row-control-opacity: 1;
            color: var(--color-high-emphasis-text);
          }
        }
      }

      & > thead {
        & > tr {
          & > th {
            --sort-indicator-opacity: 0;
            --sort-indicator-scale-y: 1;
            --flex-direction: row;

            &.alignEnd {
              --flex-direction: row-reverse;
            }

            &.alignCenter button {
              justify-content: center;
            }

            &.sorted {
              --sort-indicator-opacity: 1;

              &.descending {
                --sort-indicator-scale-y: -1;
              }
            }

            &.grid {
              border-top: none;
            }

            &.control {
              width: 48px;
            }

            vertical-align: bottom;
            background-color: var(--color-surface-medium1);

            & > .cellContent {
              & > .sortIndicator {
                opacity: var(--sort-indicator-opacity);
                transform: scaleY(var(--sort-indicator-scale-y));
                transition: opacity 250ms, transform 250ms;
                width: var(--sort-width);
              }

              & > .headerCellLines {
                text-align: var(--text-align);
                width: calc(100% - var(--sort-width) - var(--header-control-button-gap));

                & .labelLine {
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                }
              }
            }

            & > button {
              flex-direction: var(--flex-direction);
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }

      & > tbody {
        font-size: 13px;

        & > tr {
          --bg-color: transparent;
          --cell-padding: 0;
          --row-control-button-bg-color: var(--color-neutral-300);

          & .grid {
            --bg-color: var(--color-surface-dark1);
          }

          &:hover {
            --bg-color: var(--color-neutral-250);
          }

          &.selected {
            --bg-color: var(--color-neutral-300);
            --border-color: var(--color-neutral-350);
            --row-control-button-bg-color: var(--color-neutral-450);
          }

          & > td {
            &.bannerCell {
              --bg-color: var(--color-surface-background);
              --cell-padding: 16px;
            }

            &.headerStyle {
              background-color: var(--color-surface-medium1);
            }

            vertical-align: top;
            background-color: var(--bg-color);
            transition: background-color 250ms;
            height: 100%; // See note (*)

            &.menuCell {
              position: relative;

              & > button.rowControl {
                padding: 0;
                width: 26px;
                height: 26px;
                opacity: var(--row-control-opacity);
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                background-color: var(--row-control-button-bg-color);
                position: absolute;
                right: 32px;
                top: calc(50% - 13px);
                z-index: 2;
              }
            }

            & > a {
              text-decoration: none;
              color: inherit;
            }

            & .cellContent {
              &.nowrap {
                white-space: nowrap;
              }

              &.textUpper {
                text-transform: uppercase;
              }

              &.textLower {
                text-transform: lowercase;
              }

              &.textTitle {
                text-transform: capitalize;
              }

              &.fullHeight {
                height: 100%;
              }
            }
          }
        }
      }
    }
  }

  & > .accessories {
    display: flex;
    justify-content: flex-end;
    position: relative;
    z-index: 0;
  }
}

// (*) Note: in order to apply 100% height to the anchor tags in a cell, the
// table, tr, and td elements must have height set so that the <a> element's
// height: 100% will have an effect.  A value of 1px is set on the table, while
// a value of 100% is set on the tr/td elements.
