/* Copyright 2022-2024 Luminary Cloud, Inc. All Rights Reserved. */

.tableMapInput {
  --background-color: var(--color-surface-dark2);

    & > .tableinfo {
      &.columnTable {
      --background-color: var(--color-surface-dark3);
    }
  }

  & > .tableinfo {
    --vertical-gap: 8px;

    &.collapsed {
      --vertical-gap: 0;
    }

    flex: 1 1 auto;
    background-color: var(--background-color);
    border-radius: 6px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: var(--vertical-gap);
    transition: gap 250ms;

    & > .header {
      --control-color: var(--color-neutral-650);

      &:hover {
        --control-color: var(--color-neutral-750);
      }

      display: flex;
      justify-content: space-between;
      align-items: center;
      overflow: hidden;
      gap: 8px;

      & > .name {
        flex: 1 1 auto;
        color: var(--color-high-emphasis-text);
        font-size: 14px;
        font-weight: 400;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &.dialogPreview:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      & > .controls {
        flex: 0 0 auto;
        display: flex;
        align-items: flex-end;
        gap: 4px;
      }
    }

    & button.control {
      &:enabled {

        &:hover,
        &:focus {
          --control-color: var(--color-neutral-900);
        }

        &:active {
          --control-offset: 1px;
        }
      }

      &:disabled {
        --control-color: var(--color-neutral-650);
      }

      & {
        outline: 0;
        padding: 0;
        border: 0;
        margin: 0;
        background-color: transparent;
        cursor: pointer;
        color: var(--control-color);
        transition: color 250ms, transform 250ms;
        display: inline-flex;

        &:active {
          transform: translateY(var(--control-offset));
        }

        & .controlIcon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 12px;
          height: 12px;
        }
      }

    }

    & .body {
      display: flex;
      flex-direction: column;
      gap: 8px;

      & > .divider {
        height: 1px;
        background-color: var(--color-surface-light2);
      }

      & > .records {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 4px 8px;
        overflow: hidden;

        & > .label {
          color: var(--color-low-emphasis-text);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 12px;
        }

        & > .chart {
          display: flex;
          flex-direction: column;
          background-color: var(--color-surface-background);

          &.columnChart {
            background-color: var(--background-color);
          }

          & .label {
            font-size: 13px;
            color: var(--color-low-emphasis-text);
          }
        }
      }

      & > .axisSelect {
        display: flex;
        justify-content: flex-end;
      }
    }

    // styles for a column selector variant
    &.columnTable {
      padding: 0;
      gap: 0;

      & > .header {
        height: 26px;
        padding: 0px 6px;

        & > .name {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }
      }

      & > .records {
        padding: 8px;
      }

      & .body {
        border-radius: 8px;
      }

      & .chart {
        margin: auto;
        background-color: transparent;
      }
    }
  }

  & > .fieldInput {
    & > .columnSelector {
      position: absolute;
      z-index: 10;
      padding-top: 3px;
    }
  }
}
