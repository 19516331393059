/* Copyright 2022 Luminary Cloud, Inc. All Rights Reserved. */
.folderTriangle {
  display: inline-flex;
  width: 6px;
  height: 6px;
  justify-content: center;
  align-items: center;
  transform: translateX(1px) rotate(-90deg);
  transition: transform 250ms;

  &.open {
    transform: rotate(0deg);
  }
}
