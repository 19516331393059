/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.loading-ellipsis1 {
  animation: loading1 2s infinite;
}

.loading-ellipsis2 {
  animation: loading2 2s infinite;
}

.loading-ellipsis3 {
  animation: loading3 2s infinite;
}


@keyframes loading1 {
  0% {
    opacity: 0;
  }
  24% {
    opacity: 0
  }
  25% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading2 {
  0% {
    opacity: 0;
  }
  49% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}

@keyframes loading3 {
  0% {
    opacity: 0;
  }
  74% {
    opacity: 0;
  }
  75% {
    opacity: 1;
  }
  100% {
    opacity: 1;
  }
}
