/* Copyright 2023 Luminary Cloud, Inc. All Rights Reserved. */
.dotToggle {
  --diameter: calc(2 * var(--radius));
  --dot-diameter: calc(2 * var(--dot-radius));
  --background-color: var(--color-neutral-200);
  --border-color: var(--background-color);
  --outline-color: var(--background-color);
  --dot-color: var(--background-color);

  &.borderVisible {
    --border-color: var(--color-neutral-400);
  }

  &:hover {
    --background-color: var(--color-neutral-300);
    --border-color: var(--color-neutral-400);
  }

  &:focus {
    --outline-color: var(--color-primary-cta);
  }

  &.on {
    --dot-color: var(--color-primary-cta);
  }

  cursor: pointer;
  display: inline-flex;
  height: var(--diameter);
  width: var(--diameter);
  border-radius: var(--radius);
  border: var(--border-thickness) solid var(--border-color);
  outline: var(--border-thickness) solid var(--outline-color);
  justify-content: center;
  align-items: center;
  background-color: var(--background-color);

  &>.dot {
    height: var(--dot-diameter);
    width: var(--dot-diameter);
    border-radius: var(--dot-radius);
    background-color: var(--dot-color);
  }
}
