/* Copyright 2022-2023 Luminary Cloud, Inc. All Rights Reserved. */
.numberSliderCombo {
  display: flex;
  align-items: center;
  gap: 8px;

  & > .spinner {
    flex: 33%;
    width: var(--number-spinner-width);
  }

  & > .slider {
    flex: 66%;
    padding: 0 8px;
  }

  &.vertical {
    flex-direction: column;
    align-items: stretch;

    & > .spinner {
      flex: 50%;
      width: auto;
      margin-top: 5px;
    }
  }
}
