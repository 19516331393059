/* Copyright 2024 Luminary Cloud, Inc. All Rights Reserved. */
.dateSelectContainer {
    --field-bg-color: var(--color-neutral-450);
    --display-cursor: pointer;
    --label-user-select: text;
    --field-shadow: inset 0 -1px 0 var(--color-neutral-300), inset 0 1px 4px rgba(24, 25, 30, 0.1);
    --label-color: var(--color-high-emphasis-text);
    --placeholder-color: var(--color-low-emphasis-text);
    --base-padding: 8px 12px;
    --font-size: 14px;
    --line-height: 20px;
    border-radius: 4px;
    background-color: var(--field-bg-color);
    padding: var(--base-padding);
    display: flex;
    align-items: center;
    outline: 0;
    max-width: 100%;
    position: relative;
    cursor: var(--display-cursor);
  }
  
  .placeholder {
    margin-right: 24px;
  }
  
  .react-datepicker {
    background-color: var(--color-surface-medium2);
    color: var(--color-high-emphasis-text);
  }
  
  .react-datepicker__header {
    background-color: var(--field-bg-color);
    border-bottom: 1px solid var(--color-high-emphasis-border);
  }
  
  .react-datepicker__day,
  .react-datepicker__day-name {
    color: var(--color-high-emphasis-text);
  }
  
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    background-color: var(--color-surface-dark2);
    color: var(--color-low-emphasis-text);
  }
  
  .react-datepicker__day:hover {
    background-color: var(--color-neutral-800);
    color: var(--color-high-emphasis-text);
  }
  
  .react-datepicker__current-month,
  .react-datepicker__navigation {
    color: var(--color-high-emphasis-text);
  }